.engine-moves-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin: 4px auto;
  text-align: left;
  min-width: 300px;
}

.engine-mobile-container {
  padding: 10px;
  background: #f9f9f9;
}

.return-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: rgba(100, 100, 100, .25);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.return-button:hover {
    background-color: rgba(100, 100, 100, .4);
}

.stockfish-button {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: var(--highlight-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
}

.stockfish-button.active {
  background-color: #888; /* Red when active */
}

.stockfish-button:hover {
  opacity: 0.8;
}

.analysis-lines {
  display: flex;
  flex-direction: column;
}

.engine-line {
  border: 1px solid rgba(100, 100, 100, 0.2);
  padding: 2px;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  width: 100%;
  background-color: #f5f5f5;
}

.engine-line:first-child {
  border-top-left-radius: 8px; /* Rounded top-left corner */
  border-top-right-radius: 8px; /* Rounded top-right corner */
}

.engine-line:last-child {
  border-bottom-left-radius: 8px; /* Rounded bottom-left corner */
  border-bottom-right-radius: 8px; /* Rounded bottom-right corner */
}


.line-content {
  margin-left: 10px;
  font-size: 13px;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px; /* Limit line width to avoid wrapping */
}

.evaluation {
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

/* Container Styling */
.sideline-container {
  margin-top: 4px;
  padding: 1px 2px;
  max-height: 60px;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox: thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
}

/* Entry Styling */
.sideline-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Header Styling */
.sideline-header {
  display: flex;
  align-items: center;
  gap: 2px;
}

/* Selected Label Styling */
.selected-label {
  font-weight: bold;
  color: #0073e6;
}

/* Move List Styling */
.sideline-move-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 0px;
  width: 190px;
  font-size: 12px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  text-overflow: ellipsis;
  }

.sideline-move {
  padding: 0px 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.sideline-move:hover {
  background-color: #f0f0f0;
}

.sideline-move.selected {
  background-color: var(--highlight-color-trans);
  color: black;
  font-weight: bold;
}

/* Button Styling */
.nav-arrow {
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  padding: 0px 0px;
  color: #555;
  transition: color 0.2s;
}

.nav-arrow:hover {
  color: var(--highlight-color-dark);
}

.direction-button {
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  padding: 0px 9px;
  color: #555;
  transition: color 0.2s;
}

.direction button:hover {
  color: var(--highlight-color-dark);
}

.delete-sideline-btn {
  background: none;
  border: none;
  font-size: 16px;
  color: #555;
  cursor: pointer;
  transition: color 0.2s;
}

.delete-sideline-btn:hover {
  color: var(--highlight-color-dark);
}

.return-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.ai-container {
  max-height: 600px;
  grid-column: 1;
  position: relative;
  grid-row: 1;
  width: 96%;
  box-sizing: border-box;
  justify-self: right;
  min-height: 400px;
  min-width: 300px;
  background-color: #ffffff;
  border-radius: 12px; /* Rounded corners */
  padding: 15px; /* Inner spacing */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* Subtle shadow */
  border: 1px solid #e5e7eb; /* Light border for structure */
  font-family: var(--font), sans-serif; /* Clean font */
  color: #374151; /* Neutral text color */
  overflow-y: auto; /* Handle overflow content */
}

@media (max-width: 768px) {
.return-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.multipv-controls {
   display: flex;
   flex-direction: column;
}

.engine-moves-container {
  margin: 0px;
  flex-direction: row !important;
  max-width: 94vw;
  border: border-box;
  box-sizing: border-box;
  margin-top: 20px;
}

.analysis-lines {
  min-width: 250px;
}

.return-button {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
  max-width: 270px;
}

}
