.game-layout, .endgame-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: 10px;
  place-items: start center;
  height: 80vh;
  align-items: center;
}

html, body {
  height: 100%;
  overflow: hidden;
}

/* Initial state: Sidebar is not displayed on desktop */
.sidebar-mobile {

}

.content {
  margin-left: 0; /* Content uses full width on desktop */
}

.left-grid{
  grid-column: 1;
}

.captures-container{
  grid-column: 1; /* Left side */
  margin-top: 35vh;
  justify-self: right;
}

.captures-container-absolute {
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translateX(-50%);
}



.chessboard-container {
  grid-column: 2; /* Center */
  z-index: 9;
  position: relative;
  margin-top: 10px;
}


/* Base styles for .move-history-container */
.move-history-container {
  margin-top: 0px;
  grid-column: 3; /* Right side */
  width: 95%;
  max-width: 270px;
  min-height: 40%; /* Minimum height of its grid row */
  max-height: 100%; /* Maximum height of its grid row */
  justify-self: left;
}


.move-input-container {
  grid-column: 2 / 3; /* Stretches across all columns */
  align-self: start;
  }

  .peek-toggle {
    position: fixed; /* Fixed or absolute depending on your layout */
    right: 10px;
    top: 10px;
    z-index: 15;
  }

.extra-buttons-container {
    position: absolute;
    top: 0%;  /* Center vertically relative to its position context */
    right: 100%;  /* Adjust this value to position it exactly where you want next to the chessboard */
    z-index: 10;  /* Ensure it's above other content if necessary */
    margin-right: 10px;
}

.peek-button {
    position: relative;  /* Each button is positioned absolutely within the container */
    width: 30px;  /* Or whatever width you need */
    height: 30px;  /* Adjust height accordingly */
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    background-color: var(--highlight-color); /* Blue background */
    border-radius: 5px;
    color: white;
      display: flex;
  justify-content: center;
  align-items: center;
}

.peek-button img {
  width: 155%; /* Adjust based on the size of the buttons */
  height: auto;
}


.peek-button:hover {
  background-color: var(--highlight-color-dark); /* Darker shade on hover */
  transform: scale(1.05); /* Slightly increase size on hover */
}

.peek-button.off {
  background-color: #555; /* Grey for "off" state */
}

.peek-button.off:hover {
  background-color: #333; /* Darker grey on hover */
}

/* Additional styling for the placeholder text */
.chessboard-placeholder p {
  padding: 20px; /* Ensures the text doesn't touch the edges */
  border: 2px solid white; /* Gives a bordered effect to the text */
  border-radius: 10px; /* Optional: Rounds the corners of the border */
  z-index: 1000;
}

.input-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-form input:focus {
  outline: 1px solid var(--highlight-color); /* Replace #yourColorHere with your desired color */
}

.move-input-blindfold {
  margin-bottom: 10px;
  width: 500px;
  height: 7px;
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 90%;
}


.action-buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.backspace-button, .submit-move-button {
  width: 95px;
  height: 40px;
}

/* Additional button styling */
.keyboard-button {
  display: none;
  width: 50px;
  height: 40px;
  font-size: 16px;
}

.button-row, .action-buttons {
  display: flex;
  justify-content: center;
  gap: 5px; /* Space between buttons */

}

.keyboard-button, .hash-button, .backspace-button, .submit-move-button {
  display: none;
  cursor: pointer;
  background-color: var(--secondary-color);
}

.keyboard-button2 {
  visibility: hidden;
  padding: 10px 20px;
  background-color: var(--highlight-color);
  color: white;
  border: none;
  border-radius: 5px;
  width: 70%;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.keyboard-button2:hover {
  background-color: var(--highlight-color-dark);
  transform: scale(1.05);
}

.keyboard-button2.off {
  background-color: #555; /* Grey for "off" state */
  margin-top: 50%;
}

/* move history stuff */

.move-history table {
  width: 100%;
  border-collapse: collapse; /* Collapse borders between table cells */
}

.move-history th, .move-history td {
  border: 1px solid #ddd; /* Light grey border */
  text-align: left;
  padding: 4px; /* Adjust padding as needed */
  min-width: 20px; /* Set a minimum width for cells */
}

.move-history td.selected {
  background-color: var(--highlight-color); /* Light grey background */
}

.move {
  text-align: left; /* Centers text horizontally */
  padding: 7px; /* Adds space inside the cells around the content */
  font-size: 12px; /* Sets the font size */
  color: #333; /* Sets the text color */
  background-color: #f9f9f9; /* Sets the background color of the cell */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  transition: background-color 0.3s; /* Smooth transition for background color change on hover */
  min-width: 75px; /* Set a minimum width for cells */
  height: 18px;
  font-weight: normal;
}

.move-number {
  background-color: #f3f3f3; /* Example: light background color for number column */
  max-width: 40px;
  text-align: center;
  min-width: 30px;
  color: #333; /* Dark gray text color */
}


.move:hover {
  background-color: var(--highlight-color-dark); /* Example: change background color on hover */
  /* Additional hover styling for move cells */
}

.selected {
  background-color: var(--highlight-color-trans); /* Example: different background color for selected move */
  font-weight: bold;
  /* Additional selected move styling */
}

.history-controls {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 0px; /* Space between the controls and the move history table */
  background-color: #f5f5f5; /* Light gray background */
  border-radius: 5px; /* Rounded corners */
}

.history-controls button {
  padding: 5px 10px;
  background-color: var(--secondary-color); /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.history-controls button img {
  width: 24px; /* Sets a fixed width for the image */
  height: 24px; /* Sets a fixed height for the image */
}

.history-controls button:hover {
  background-color: var(--highlight-color-dark); /* Darker shade for hover effect */
}

.history-controls button:disabled {
  background-color: #cccccc; /* Disabled button color */
  cursor: not-allowed;
}

.move-history-scroll {
  max-height: 300px; /* Adjust based on your preference */
  min-width: 200px; /* Adjust based on your preference or make it responsive */
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  .move-history-container::-webkit-scrollbar {
  display: none;

}


/* GAME OVER stuff */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.gameover-modal-content {
  background-color: var(--background-color);
  width: 30%;
  height: auto;
  max-height: 30%;
  padding: 20px;
  border-radius: 5px;
  z-index: 1001;
  text-align: center;
}

.share-button-container {
  position: relative;
}

.share-button-container button {
  width: 50px !important;
}

.share-options {
  position: absolute;
  top: 100%; /* Adjust as needed to position below the toggle button */
  left: 0;
  background-color: white; /* Or any color you prefer */
  box-shadow: 0 6px 11px rgba(0,0,0,0.2); /* Optional */
  z-index: 10; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.share-option {
  cursor: pointer;
  background-color: var(--highlight-color) !important;
  width: 78px;
  padding: 2px 5px;
  text-align: center;
  font-size: 12px; /* Adjust this value to change the text size */
  line-height: 15px; /* Optionally, adjust line height for better readability */
  margin: 5px; /* Add margin around each button */
}

.share-option button {
}

/* Remove the border from the last option */
.share-option:last-child {
  border-bottom: none;
}

.share-option:hover {
  background-color: var(--highlight-color-dark) !important;
}

.promotion-modal-container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promotion-options img {
    width: 50px;
    height: 50px;
    margin: 5px;
    cursor: pointer;
}

.promotion-options {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}


.options-menu {
  position: absolute;
  top: 0%;
  right: 100%;
  z-index: 20;
  background-color: #fff;
  padding: 15px;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.options-menu button {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.options-menu input[type="color"] {
  border-radius: 5px;
  cursor: pointer;
}


/* General Styles for when the viewport width is below 850px */
@media (max-width: 850px) {
    .game-layout {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: 100dvh;
        height: auto;
        place-items: start center;
        flex-start: center;

    }

    .chessboard-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-shrink: 0;
        flex-grow: 0;
        margin-top: 0px;
    }

    .captures-container {
        display: none;
    }

.move-history-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    order: -1;
    min-height: 0;
    max-height: 20vh;
    max-width: 450px;
    width: 100%; /* Optional: Ensure it takes full width if necessary */
    margin-left: auto;
    margin-right: auto;
}


    .move-history-scroll {
        max-height: 50px;
        width: 100%;
        overflow-y: auto;
        background-color: white;
        padding: 5px;
        border-radius: 8px;
    }

    .history-controls {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: #f5f5f5;
        padding: 0px;
        border-radius: 5px;
        gap: 20px;
    }

    .history-controls button {
        padding: 2px;
        background-color: var(--secondary-color);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 70px;
        transition: background-color 0.3s ease;
    }

    .history-controls button img {
        width: 20px;
        height: 20px;
    }

    .history-controls button:hover {
        background-color: var(--highlight-color-dark);
    }

    .history-controls button:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }

    .move-input-container {
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
        align-self: start;
    }

    .move-input-blindfold {
        margin-bottom: 20px;
        width: 90%;
        font-size: 14px;
    }

    .move {
        padding: 5px;
        width: 50%;
        padding: 7px;
        font-size: 12px;
    }

    .share-button-container {
        position: relative;
    }

    .share-options {
        position: absolute;
        height: 160px;
        width: 112px;
        top: 100%;
        left: -100%;
        margin-top: 5px;
    }

    .share-option {
        background-color: var(--highlight-color) !important;
        width: 100%;
        height: 90px;
        padding: 3px 5px;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        margin: 5px;
    }

    .options-menu {
        position: fixed;

        top: 0;
        left: 0px;
        width: 100px;
        height: 110px;
        z-index: 1000;
    }

    .options-menu button {
        padding: 0px;
        border-radius: 5px;
    }

    .extra-buttons-container {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 10;
        top: auto;
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }

    .peek-button {
        width: 35px;
        height: 35px;
        margin: 5px;
        border-radius: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }

    .peek-button img {
        width: 100%;
        height: auto;
    }

    .peek-button.off {
        background-color: #555;
    }

    .peek-button.off:hover {
        background-color: #555;
        transform: scale(1.0);
    }

    .no-scroll {
        overflow: hidden;
        touch-action: none;
    }

    .gameover-modal-content {
      width: 95%;
      height: 25%;
    }
}
