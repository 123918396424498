.endgame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh; /* Use full height */
}

.endgame-layout {
  display: flex;
  height: calc(100% - 60px); /* Adjust the height based on your header */
}

.endgame-sidebar {
  background-color: var(--background-color);
  width: 250px; /* Sidebar width */
  overflow-y: auto; /* Enable scrolling */
  border-right: 1px solid #ddd;
  height: calc(100vh - 100px); /* Adjust 60px to match your header height */
}

.endgame-level-item {
  padding: 15px;
  margin: 5px 0;
  justify-content: space-between;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.endgame-level-item:hover {
  background-color: var(--highlight-color);
  color: white;
}

.endgame-level-item.selected {
  background-color: var(--highlight-color-trans);
  color: white;
  font-weight: bold;
}

.endgame-puzzles {
  position: relative;
  flex-grow: 1; /* Take up remaining space */
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  grid-auto-rows: 85px;
  grid-gap: 35px;
  overflow-y: auto; /* Enable scrolling */
  z-index: 10;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
}


.endgame-puzzle {
  position: relative;
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
}

.endgame-puzzle-content {
  text-align: center;
}

.endgame-puzzle:hover {
  background-color: var(--highlight-color);
}

.endgame-puzzle:hover .chessboard-preview {
  display: block; /* Assuming you set display:none when not hovered */
}

.game-details-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: auto; /* Pushes it to the far right */
  width: 200px; /* Adjust width if needed */
}

/* Dynamic Borders Based on Due Date */
.game-details-container.border-green {
  border: 3px solid #28a745; /* Green - Enough time */
}

.game-details-container.border-yellow {
  border: 3px solid #ffc107; /* Yellow - Due soon */
}

.game-details-container.border-red {
  border: 3px solid #dc3545; /* Red - Overdue */
}

.highlight-green {
  background-color: #d4edda; /* Green for on-track */
  color: #155724;
  border: 1px solid #c3e6cb;
}

.highlight-red {
  background-color: #f8d7da; /* Red for off-track */
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.highlight-neutral {
  background-color: #e0e0e0;
  color: #333;
}

.highlight-gray {
  background-color: #e9ecef;
  color: #495057;
}

/* Attempts Container */
.attempts-container {
  display: flex;
  gap: 5px;
}

.horizontal-row {
  display: flex;              /* Make the buttons align horizontally */
  justify-content: center;    /* Center them horizontally */
  align-items: center;
  gap: 10px;                  /* Add space between buttons */
  margin-top: 10px;           /* Add some space above the buttons */
}

/* Success Box */
.success-box {
  width: 15px;
  height: 15px;
  background-color: #28a745; /* Green for success */
  border-radius: 3px;
}

/* Failure Box */
.failure-box {
  width: 15px;
  height: 15px;
  background-color: #dc3545; /* Red for failure */
  border-radius: 3px;
}

/* Default Box (No Style for Other Cases) */

/* Stars */
.stars-container-large {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.star {
  font-size: 20px;
  color: lightgray;
}

.star.filled {
  color: gold;
}

/* Attempts History */
.success {
  color: green;
}

.failure {
  color: red;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.info-box {
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.highlight-goal {
  background-color: #e3f2fd; /* Light blue */
  color: #1565c0;
}

.highlight-eval {
  background-color: #fbe9e7; /* Light red-orange */
  color: #d84315;
}

.highlight-success {
  background-color: #e8f5e9; /* Light green */
  color: #388e3c;
}

.highlight-warning {
  background-color: #fff8e1; /* Light yellow */
  color: #ffa000;
}

.highlight-result {
  background-color: #ede7f6; /* Light purple */
  color: #5e35b1;
}

/* For small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
    .endgame-wrapper {
        flex-direction: column;
    }

    .back-button {
      position: absolute;
      top: 0px;
      left: 3px;
      margin: 10px;
      padding: 8px 12px;
      font-size: 14px;
      background-color: #eee;
      border: none;
      border-radius: 6px;
      cursor: pointer;
    }
    

    .endgame-layout {
        padding: 20px;
        flex-direction: column;
        height: auto; /* Adjust height for mobile to be auto */
        overflow: hidden;
    }

    .endgame-sidebar {
        width: 100%; /* Full width */
        overflow-y: auto;
        border-right: none;
        height: 100dvh;
    }

    .endgame-puzzles {
        padding: 10px; /* Reduced padding */
        padding-top: 60px;
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr)); /* Keep the grid responsive */
        grid-auto-rows: 70px; /* Maintain row height */
        padding-right: 10px; /* Adjust padding */
        padding-left: 10px;
    }

    .endgame-category-button {
        padding: 8px; /* Slightly smaller padding */
    }

    .endgame-subcategories {
        background-color: #f0f0f0; /* Lighter background for mobile */
    }

    .game-details-container {
      order: 10;
      margin-top: 20px;
      width: 100%; /* Adjust width if needed */
      box-sizing: border-box;
    }
}