.manage-subscriptions {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.subscription-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.subscription-table th,
.subscription-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.subscription-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.subscribe-button,
.manage-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subscribe-button:disabled {
  background-color: #ccc;
}

.subscribe-button:hover,
.manage-button:hover {
  background-color: #0056b3;
}

.subscription-status {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}
