.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  padding: 20px;
  background-color: white; /* Subtle background */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Drop shadow */
  position: relative;
}

.feedback {
  font-size: 1.2em;
  color: var(--text-color2);
  margin-bottom: 15px;
}

.instruction {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.help-button {
  background-color: var(--highlight-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.help-button:hover {
  background-color: var(--highlight-color-dark);
}

.coordinate-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 20px 40px;
    border-radius: 8px;
    text-align: center;
}

.overlay-text {
    color: #f9f9f9;
    font-size: 6rem;
    font-weight: bold;
}

.leaderboard {
    border-radius: 8px;
    padding: 8px;
    max-width: 250px;
    background-color: transparent;
    text-align: left;
}

.leaderboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.leaderboard h3 {
    font-size: 1em;
    color: #333;
    margin: 0;
    font-weight: 600;
}

.leaderboard-toggle-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    font-size: 1.1em;
    cursor: pointer;
    padding: 4px;
    color: #666;
    border: none;
    border-radius: 50%;
    transition: color 0.3s, background-color 0.3s;
}

.leaderboard-toggle-button:hover {
    color: #333;
    background-color: var(--highlight-color-trans);
}

.leaderboard-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.leaderboard-item {
    display: flex;
    justify-content: space-between;
    padding: 0px 0;
    font-size: 0.8em;
    color: #555;
}

.rank-1 .rank { color: #D4AF37; font-weight: bold; } /* Gold */
.rank-2 .rank { color: #C0C0C0; font-weight: bold; } /* Silver */
.rank-3 .rank { color: #CD7F32; font-weight: bold; } /* Bronze */

.leaderboard-item .rank {
    width: 20px;
}

.username {
    flex: 1;
    text-align: left;
    font-weight: 500;
}

.score {
    font-weight: bold;
    color: #333;
}

.rank-1 .username, .rank-1 .score { color: #D4AF37; }
.rank-2 .username, .rank-2 .score { color: #C0C0C0; }
.rank-3 .username, .rank-3 .score { color: #CD7F32; }


/* Adjustments for responsiveness */
@media (max-width: 768px) {

  .feedback-container {
    width: 80%; /* Take up full width */
    height: 200px;
    margin: 0 auto; /* Horizontally center the container */
    text-align: center; /* Center the text */
    order: -1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: none;
    padding: 0px;
    background-color: var(--background-color); /* Subtle background */
    border-radius: 8px;
    box-shadow: none; /* Drop shadow */
  }

  .help-button {
    width: 100%; /* Full-width button on mobile */
  }
}
