.train-container {
  text-align: center;
  padding: 20px;
  max-width: 80%;
  margin: auto;
  font-family: var(--font);
}

.train-title {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.next-up-box {
  width: 400px;
  justify-self: center;
  background-color: var(--primary-color);
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  border-shadow: rgba(0, 0, 0, 0.5);
}

.train-text {
  color: var(--text-color1);
  font-size: 16px;
  margin-bottom: 10px;
}

.progress-bar-container {
  width: 100%;
  height: 12px;
  background: var(--secondary-color);
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: var(--highlight-color);
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.train-subtitle {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.next-up {
  font-size: 18px;
  color: white;
}

.train-button {
  background-color: var(--highlight-color);
  color: white;
  border: none;
  width: 250px;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 26px;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px;
  border-radius: 20px;
}

.train-button:hover {
  background-color: var(--highlight-color-dark);
}

.train-button.disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

.edit-button {
  margin-top: 10px;
  padding: 10px 15px;
  background: white;
  border: none;
  border-radius: 5px;
  color: var(--text-color1);
  background-color: var(--background-color);
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.edit-button:hover {
  background: rgba(200, 200, 200, 0.5);
}

.study-plan-container {
  margin-top: 30px;
  text-align: left;
}

.study-plan-list {
  list-style: none;
  padding: 0;
}

.study-plan-item {
  padding: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  color: var(--text-color1);
  font-size: 16px;
}

.next-up-note {
  font-size: 14px;
  color: white;
  margin-top: 5px;
  padding-left: 10px;
  border-left: 2px solid #ccc;
}

.edit-container {
  text-align: center;
  padding: 20px;
  max-width: 1100px;
  margin: auto;
  font-family: var(--font);
  background-color: var(--background-color);
}

.edit-textarea {
  height: 45px !important; /* Forces height */
  min-height: 40px !important; /* Ensures it doesn't shrink smaller */
  max-height: 100px !important; /* Prevents it from expanding too tall */
}

.edit-title {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.edit-label {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
  color: var(--text-color1);
}

.edit-input {
  padding: 8px;
  width: 100px;
  text-align: center;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s ease-in-out;
}

.study-plan-section {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-select {
  flex: 2;
  padding: 8px;
  border-radius: 5px;
  border: 2px solid var(--secondary-color);
  font-size: 16px;
}

.remove-button, .add-button {
  padding: 8px 12px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.remove-button { background: transparent; color: gray; }
.add-button { background: var(--primary-color); color: white; }

/* Training Overlay */
.overlay-container {
  position: absolute;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: grab;
  z-index: 1000;
}

.overlay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-tab {
  background: var(--highlight-color);
  color: white;
  cursor: pointer;
}

.overlay-tab:hover {
  background: var(--highlight-color-dark);
}

.overlay-buttons {
  display: flex;
  gap: 10px;
}

.expand-button, .hide-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}


.overlay-container.dragging {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.7);
  cursor: grabbing;
}

.overlay-timer {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-color);
}

.next-button, .stop-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.next-button {
  background: var(--primary-color);
  color: white;
}

.next-button:hover {
  background: var(--highlight-color-dark);
}

.stop-button {
  background: red;
  color: white;
}

.stop-button:hover {
  background: darkred;
}


.play-options-container {
  max-width: 600px;
  margin: 60px auto;
  padding: 2rem;
  text-align: center;
}

.play-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.play-description {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
}

.play-button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.return-note {
  font-size: 0.95rem;
  color: #666;
}
