.quiz-container {
  grid-column: 1;
  position: absolute;
  max-width: 200px;
  min-width: 100px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  top: 60%; /* Center vertically */
  left: 0%;
  transform: translate(-110%, -50%); /* Adjust to exactly center based on its own size */
  z-index: 10; /* Ensure it's above other content */
}


.quiz-container p,
.quiz-container form,
.quiz-container button {
  margin-bottom: 10px;
  color: var(--dark-text);
}

.quiz-container input[type="text"] {
  padding: 8px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.quiz-container button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--highlight-color);
  color: white;
}

.quiz-container button:hover {
  background-color: var(--highlight-color-dark);
}

.solution-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 5px;
  background-color: transparent; /* Default background color, overridden by specific classes */
  border: 1px solid black;
}

.correct-answer {
  background-color: green;
}

.quiz-success-message {
  animation: fadeIn 1s;
  font-size: 1.5em;
  color: green;
}

/* Define a fadeIn animation if you don't already have one */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.solution-box.correct-answer {
  display: inline-block;
  padding: 5px;
  margin: 2px;
  border: 1px solid #ddd;
  background-color: green;
  color: white; /* Ensure text is visible on the green background */
}

.solution-box.answer {
  display: inline-block;
  width: 50px !important;
  padding: 5px;
  margin: 2px;
  border: 1px solid #333;
  background-color: green;
  color: white; /* Ensure text is visible on the green background */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.quiz-container.hide {
  animation: fadeOut 1s forwards;
}

.canvas-container {
  grid-column: 2 /3; /* Center */
  /* Since it's centered, you may not need display: flex here unless for content inside */
  left: 67%;
  z-index: 11;
  position: absolute;
}

.canvas-blocking {
  pointer-events: auto; /* Ensure it captures pointer events */
  z-index: 100; /* Example z-index to ensure it's on top */
}

.top-canvas {
  pointer-events: auto;
}

.quiz-options-container {
  text-align: center; /* Center the header */
}

.radio-buttons-container {
  display: flex;
  justify-content: center; /* Center the flex items (labels and radio buttons) */
  gap: 20px; /* Optional: add some space between the radio buttons */
  margin-top: 20px; /* Space between the header and the radio buttons */
  margin-bottom: 30px; /* Space between the header and the radio buttons */
}

.radio-buttons-container label {
  display: flex;
  align-items: center; /* Vertically center the label text with the radio button */
}

.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Match the canvas size */
  height: 100%; /* Match the canvas size */
  background-color: rgba(255, 0, 0, 0.5); /* Semi-transparent red */
  pointer-events: none; /* Allow clicks to pass through */
}

@media (max-width: 768px) {
  .quiz-container {
    position: relative; /* Adjust positioning for mobile */
    left: 50%; /* Center align the container */
    transform: translate(-50%, -50%); /* Adjust transform to center the container properly */
    max-width: 90%; /* Increase max width for better use of screen space */
    min-width: 60%; /* Remove min-width to allow container to be more fluid */
    top: 50%; /* Adjust top position to better center vertically */
    padding: 10px; /* Reduce padding to save space */
    z-index: 1000;
  }
}