.help-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  overflow-y: auto; /* Allows scrolling within the container */
  line-height: 1.6;
}

h1, h2, h3 {
  color: #333;
  margin-top: 30px;
}

.screenshot-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.screenshot {
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.screenshot-small {
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.further-help {
  margin-top: 40px;
}

.further-help a {
  color: #007BFF;
  text-decoration: none;
}

.further-help a:hover {
  text-decoration: underline;
}
.gif-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto; /* Centers the container horizontally */
  padding: 20px; /* Optional padding */
}

.gif-screenshot {
  width: 100%;
  max-width: 700px; /* Adjust as needed */
  border-radius: 8px;
}

.help-icon {
  width: 20px;
  height: 20px;
  background-color: var(--secondary-color); /* Light gray background */
  padding: 7px;              /* Space around the icon */
  border-radius: 50%;         /* Rounded corners */
  margin-left: 6px;  /* Space to the left of the icon */
  margin-right: 6px;
  transform: translateY(10px);
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 15px;
}

.help-section, .page-summaries, .unique-features, .further-help {
  margin-bottom: 30px;
}

.help-page-link, .further-help-link {
  color: #007bff;
  text-decoration: none;
}

.help-page-link:hover, .further-help-link:hover {
  text-decoration: underline;
}
