.theme-customization {
  display: flex;
  flex-direction: column;
}

.color-picker-group, .selection-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.color-picker, .piece-set-selection, .marker-selection, .font-style-selection {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="color"], select {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.piece-set-preview img {
  width: auto; /* Adjust based on your layout */
  max-width: 50%; /* Ensures image is responsive */
  margin-top: 10px;
}


