:root {
  --primary-color: #287F94;
  --secondary-color: #B0B9C0;
  --highlight-color: #E3B866;
  --highlight-color-trans: #ECC282;
  --highlight-color-dark: #C19042;
  --background-color: #f8f8f8;
  --text-color1: #555555;
  --text-color2: #8A8A8A;
  --board-display: block;
  --chessboard-size: 600px;
  --font: 'Montserrat', 'sans-serif';
  --dark-square-color: #3B7688;
  --light-square-color: #F2FBFF;
  --normal-piece: 'normal';
  --marker-piece: 'marker';
  --dark-text: black;
}
/* Crown Gold #DBA63F */
/* GlobalStyles.css */
/* src/components/Modal.css */

/* index.css */
textarea {
  font-size: 16px;
  font: var(--font);
  padding: 8px;
  border-radius: 4px;
}

textarea:focus {
  border-color: var(--highlight-color); /* Change this to the color you want */
  outline: 2px solid var(--highlight-color); /* Removes the default browser outline */
}

select {
  margin: 3px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: .8em;
}

select:focus {
  border: 1px solid var(--highlight-color);
  font-size: .85em;
}

.share-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 250px;
}

.checkbox {
  accent-color: var(--highlight-color); /* Changes the color of the checkbox */
  background-color: white;
  padding: 2px; /* Reduces the padding around the checkbox */
  margin: 7px; /* Adjust spacing between checkbox and label */
  width: 16px; /* Optional: Adjust checkbox size */
  height: 16px; /* Optional: Adjust checkbox size */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* src/components/Countdown.css */

.countdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.countdown-number {
  font-size: 5rem;
  color: white;
  font-weight: bold;
  text-align: center;
}


.modal-integration-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  width: 300px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-close-button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: var(--highlight-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: var(--highlight-color-dark);
}

.no-underline {
  text-decoration: none;
}

.text-button {
  background: none;
  border: none;
  color: #222; /* Optional: styled like a link */
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.text-button:hover {
  color: var(--highlight-color);
}

.info-icon {
    display: inline-block;
    background-color: var(--highlight-color); /* Blue background for the "i" icon */
    color: white;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
}

/* Apply smooth scrolling */
html {
    scroll-behavior: smooth;
}

.tooltip-text1 {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 100;
    bottom: 125%; /* Position above the "i" icon */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    width: 200px; /* Adjust width as needed */
    max-width: 90vw; /* Ensure tooltip stays within viewport width */
    white-space: normal; /* Allow text to wrap if needed */
}

/* Tooltip arrow */
.tooltip-text1::after {
    content: '';
    position: absolute;
    top: 100%; /* Bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Hover effect to show tooltip */
.info-icon:hover .tooltip-text1 {
    visibility: visible;
    opacity: 1;
}

.center-flex {
  display: flex;
  flex-direction: column; /* Aligns items in a column */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}

/* On mobile, make tooltip text absolute and take up the full width */
@media screen and (max-width: 600px) {
    .tooltip-text1 {
        position: fixed; /* Use fixed positioning for a more controlled layout on mobile */
        bottom: 65%; /* Adjust bottom positioning */
        left: 5%;
        width: 100vw; /* Full width of viewport */
        transform: none; /* Remove center transform */
        text-align: center; /* Keep text centered */
        padding: 10px;
        box-sizing: border-box;
    }

    .tooltip-text1::after {
        display: none; /* Remove the arrow on mobile for simplicity */
    }
}


.pageStyle {
  display: 'flex';
  flexDirection: 'column';
  alignItems: 'center';
  justifyContent: 'center';
  height: '100vh';
  fontFamily: 'var(--font), sans-serif';
  color: '#333';
  textAlign: 'center';
  padding: '20px';
}

.buttonStyle {
  backgroundColor: var(--highlight-color);
  color: '#fff';
  border: 'none';
  borderRadius: '5px';
  padding: '10px 20px';
  cursor: 'pointer';
  marginTop: '20px';
  fontSize: '16px';
  transition: 'background-color 0.3s, box-shadow 0.3s';
}

.buttonStyle:hover {
  backgroundColor: var(--highlight-color-dark);
}

a, button {
  color: inherit; /* Use the text color of the parent element */
  background-color: transparent; /* Remove any background color */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight color on iOS */
}

/* Specific styling for buttons */
button {
  background-color: var(--button-background-color, #fff); /* Custom background color */
  color: var(--button-text-color, #000); /* Custom text color */
}

/* styles.css */
.btn-1 {
  display: block; /* Make the button a block-level element */
  width: 50%; /* Or a specific width */
  margin: 15px auto 0;  padding: 10px 25px;
  background-color: var(--highlight-color);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-1:hover {
  background-color: var(--highlight-color-dark);
}

.btn-2 {
  display: block; /* Make the button a block-level element */
  width: 50%; /* Or a specific width */
  margin: 15px auto 0;  padding: 10px 25px;
  background-color: #555;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-2:hover {
  background-color: #333;
}

/* styles.css */
.image-button {
  border: 2px solid 'black';
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 50px; /* Adjust based on your preference */
  height: 50px; /* Adjust based on your preference */
  cursor: pointer;
  margin: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.image-button:hover {
  transform: scale(1.1);
}

.image-button .tooltip-text {
  display: none; /* Hide tooltip text by default */
  position: absolute;
  bottom: -70%; /* Adjust positioning as needed */
  left: 50%; /* Center the tooltip */
  transform: translateX(-50%); /* Center the tooltip */
  white-space: nowrap; /* Prevent wrapping */
  background-color: var(--highlight-color); /* Tooltip background */
  color: white; /* Tooltip text color */
  padding: 5px; /* Tooltip padding */
  border-radius: 5px; /* Rounded corners for tooltip */
  font-size: 12px; /* Adjust font size as needed */
  z-index: 1; /* Ensure tooltip is above other content */
}

.image-button:hover .tooltip-text {
  display: block; /* Show tooltip text on hover */
}

.image-button img {
  width: 105%; /* Adjust based on the size of the buttons */
  height: auto;
}

.custom-size {
  width: 70% !important;
  height: auto !important;
}

.image-button-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-button.active {
  border: 2px solid var(--highlight-color); /* Highlight color */
  box-shadow: 0 0 5px var(--highlight-color); /* Optional: Add a glow effect */
}

.settings-modal-play {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  max-height: 100%;
  max-width: 500px;
  background: var(--background-color);
  overflow: hidden;
  margin-top: 10px;
  overflow-y: auto;
  padding: 0px;
}


.settings-modal-play h2 {
  color: var(--text-color1);
  text-align: center;
  font-family: var(--font), sans-serif;
  font-style: normal;
  font-display: swap;
  font-weight: 600;

}

.settings-modal-play h3 {
  color: var(--text-color1);
  text-align: center;
  font-family: var(--font), sans-serif;
  font-style: normal;
  font-display: swap;
  font-size: 18px; /* Adjusted for readability, 5px is quite small */
  font-weight: 200;
  margin-bottom: -10px; /* Reduced bottom margin to bring closer to buttons */
}


h1, h2, h3 {
    font-family: var(--font), sans-serif;
    font-weight: 600; /* Semi-bold for more impact */
    margin-bottom: 5px !important;
}


body {
    font-family: var(--font), sans-serif;
    color: var(--text-color2);
    background-color: var(--background-color);
    overflow-x: auto;
}




/* styles.css - Add this to your existing CSS file */

/* General slider styles */
.slider-play {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  max-width: 97%; /* Full width */
  display: block;
  height: 4px; /* Specified height */
  background: #5557c; /* Slider background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Partial transparency */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  padding: 4px;
}

.slider-play:hover {
  opacity: 1; /* Fully opaque on hover */
}

.average-button {
    background-color: var(--highlight-color);
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    margin: 5px;
}

.average-button:hover {
    background-color: var(--highlight-color-dark) !important;
    transform: scale(1.05);
    transition: .5s ease;
}

.subtle-button {
    background-color: rgba(205, 210, 215, .7);
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
    padding: 7px;
    margin: 3px;
}

.subtle-button:hover {
    background-color: rgba(200, 210, 215, 1);
    transform: scale(1.02);
    transition: .5s ease;
}

.wrap-image-container-right {
  float: right; /* Aligns the figure to the right */
  margin-left: 15px; /* Adds spacing between the image and text */
  margin-bottom: 10px; /* Adds spacing below the image */
  max-width: 350px; /* Limits the width of the image and caption */
  text-align: center; /* Centers the caption under the image */
}

.wrap-image-container-left {
  float: left; /* Aligns the figure to the right */
  margin-left: 15px; /* Adds spacing between the image and text */
  margin-bottom: 10px; /* Adds spacing below the image */
  max-width: 350px; /* Limits the width of the image and caption */
  text-align: center; /* Centers the caption under the image */
}

.wrap-image {
  max-width: 100%; /* Ensures the image scales with the container */
  border-radius: 8px; /* Optional: Rounds the corners of the image */
}

.image-caption {
  font-size: 0.7rem; /* Slightly smaller font for the caption */
  color: #555; /* Muted color for the caption text */
  margin-top: 2px; /* Space between the image and caption */
  font-weight: bold; /* Makes the text bold */
  line-height: 1.1;
}

ol li strong {
  margin-right: 4px; /* Adjust spacing as needed */
}

/* Slider thumb styles for various browsers */
.slider-play::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Width of the slider handle */
  height: 25px; /* Height of the slider handle */
  background: var(--highlight-color); /* Slider handle color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular shape */
}

.slider-play::-moz-range-thumb {
  width: 25px; /* Width of the slider handle */
  height: 25px; /* Height of the slider handle */
  background: var(--highlight-color); /* Slider handle color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular shape */
}

/* Label styling */
.slider-label {
  display: block; /* Make the label a block for proper alignment */
  margin-bottom: 15px; /* Space below the label */
  color: var(--text-color1); /* Label text color */
  text-align: center; /* Center align the text */
  font-size: 18px;
}


/* Custom radio button styling */
input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--highlight-color); /* Using CSS variable for border */
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px; /* Added margin on top */
  position: relative;
}

input[type="radio"]:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--highlight-color); /* Using CSS variable for background */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the circle */
  opacity: 0;
}

/* Styling when the radio button is checked */
input[type="radio"]:checked:after {
  opacity: 1;
}

/* Hover effect for labels */
label:hover input[type="radio"] {
  border-color: var(--highlight-color-dark); /* Slightly darker border on hover */
}

/* Add this to your CSS file */
input:focus {
  outline: none !important;
  border:1px solid var(--highlight-color); /* Set to your preferred color */
}

.bold-text {
  font-weight: bold;
}

.only-desktop {
}


@media (max-width: 768px) {

.only-desktop {
  display: none;
}

.image-button {
  border: 2px solid 'black';
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 40px; /* Adjust based on your preference */
  height: 40px; /* Adjust based on your preference */
  cursor: pointer;
  margin: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

  .image-button img {
    width: 150%; /* Increase image size for better visibility on smaller screens */
    height: auto; /* Maintain aspect ratio */
    }

  .image-button .tooltip-text {
    font-size: 14px; /* Increase font size for better readability */
    padding: 8px; /* Increase padding for larger touch targets */
    bottom: -100%; /* Adjust position to ensure visibility */
  }

  .custom-size {
    width: 90% !important; /* Override any previous width settings */
    height: auto !important; /* Height automatically adjusts to maintain aspect ratio */
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Subtle dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: white;
}

.knight-hop-animation {
  width: 300px; /* Adjust size as needed */
  height: 300px;
  filter: brightness(1000%) saturate(0%);
}
