.about-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    padding: 20px; /* Adjust padding for the entire page */
    max-width: 800px;
    margin: 20px auto; /* Centers the page content horizontally */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.about-page header h1 {
    text-align: center; /* Centers the header text */
    margin: 20px 0 10px; /* Adds more top margin to separate from the top of the page */
}

.about-page h2 {
    color: var(--primary-color);
    text-align: left; /* Ensures subheadings are left-aligned */
    margin: 20px 0; /* Adjusted for more vertical spacing */
}

.about-page p, .about-page ul, .about-page li {
    margin-left: 20px; /* Adds left margin to text and list for better readability */
}

.about-page ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px; /* Adjusts bottom margin for spacing after lists */
}

.about-page li {
    margin-bottom: 5px;
    padding-left: 10px;
    position: relative;
}

.about-page li:before {
    content: '•';
    color: var(--primary-color);
    position: absolute;
    left: -10px;
}

.about-page a {
    color: var(--highlight-color);
    text-decoration: none;
}

.about-page a:hover, .about-page a:focus {
    text-decoration: underline;
    color: #0056b3;
}

.about-page button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Adds margin above the button if necessary */
}

.about-page button:hover {
    background-color: #0056b3;
}

.about-page footer {
    font-size: 0.85em;
    color: #666;
    margin-top: 40px;
    text-align: center; /* Centers footer content */
}
