.browse-openings-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.openings-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.opening-card {
  width: 800px;
  height: 450px;
  perspective-origin: center;
  cursor: pointer;
  position: relative; /* Ensure both front and back are aligned */
}

.opening-card .front,
.opening-card .back {
  position: absolute;
  width: 100%;
  height: 100%; /* Allow full height instead of limiting to 50% */
  backface-visibility: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 0.6s;
  box-sizing: border-box; /* Ensures padding doesn’t affect the size */
  background-color: white;
}


.opening-card .front {
  transform: rotateY(0deg);
}

.opening-card .back {
  transform: rotateY(180deg);
  flex-direction: column;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  justify-content: center; /* Center horizontally */
}

.opening-card.flipped .front {
  transform: rotateY(180deg);
}

.opening-card.flipped .back {
  transform: rotateY(0deg);
}

.opening-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back h3 {
  font-size: 1.2em;
  margin: 10px 0;
  color: #333;
}

.back p {
  font-size: 1em;
  color: #666;
  margin: 10px 0;
}

.steps {
  padding: 10px;
}

.steps h4 {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.steps ol {
  padding-left: 1.2em;
  list-style-type: none;
}

.steps ol li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.steps ol li button {
  flex: 1;
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: left;
}

.steps ol li button:hover {
  background-color: #f0f0f0;
}

.opening-card .price-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--primary-color);
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.redemption-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

.redemption-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  margin-right: 10px;
  width: 400px;
}

@media (max-width: 768px) {
  .browse-openings-wrapper {
    padding: 10px;
  }

  .openings-list {
    gap: 10px; /* Reduce the gap between cards */
  }

  .opening-card {
    width: 400px; /* Take full width of the container */
    max-width: 95%;
    perspective: 800px; /* Adjust perspective for smaller screens */
  }

  .opening-card .front, .opening-card .back {
    border-radius: 5px; /* Slightly smaller border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtler shadow */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .opening-image {
    object-fit: contain; /* Ensure image fits within smaller dimensions */
  }

  .back h3 {
    font-size: 1em; /* Adjust font size */
    margin: 5px 0;
  }

  .back p {
    font-size: 0.9em; /* Smaller text */
    margin: 5px 0;
  }

  .steps {
    padding: 5px; /* Reduce padding for steps section */
  }

  .steps h4 {
    font-size: 1em;
    margin-bottom: 5px;
  }

  .steps ol {
    padding-left: 0.8em; /* Reduce indentation */
  }

  .steps ol li {
    margin-bottom: 8px;
  }

  .steps ol li button {
    padding: 8px 10px; /* Adjust button padding */
    font-size: 0.8em; /* Smaller font for buttons */
  }

  .opening-card .price-tag {
    top: 5px;
    right: 5px;
    font-size: 1em; /* Adjust size of price tag text */
    padding: 3px 7px;
  }

  .redemption-bar {
    flex-direction: column; /* Stack input and button vertically */
    gap: 5px; /* Add spacing between input and button */
    padding: 10px;
  }

  .redemption-input {
    width: 90%; /* Adjust width to fit smaller screens */
    margin-right: 0; /* Remove margin since input is stacked */
  }
}
