  .linktree-container {
    max-width: 480px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    color: var(--primary-color);
  }
  
  .linktree-logo img {
    width: 100px;
    height: auto;
    padding: 5px;
  }
  
  .linktree-title {
    font-size: 28px;
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .linktree-bio {
    font-size: 16px;
    color: var(--secondary-color);
    margin-bottom: 30px;
  }
  
  .linktree-links a {
    display: block;
    margin: 12px 0;
    padding: 14px 24px;
    background: var(--highlight-color);
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 12px;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .linktree-links a:hover {
    background: var(--highlight-color-dark);
    transform: scale(1.05);
  }
  