.exchange-page {
  font-family: sans-serif;
  text-align: center;
  padding: 2rem;
}

.exchange-hero {
  margin-bottom: 3rem;
}

.exchange-title {
  font-size: 3rem;
  font-weight: bold;
}

.exchange-tagline {
  font-size: 1.2rem;
  color: #666;
}

.download-section {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.platform-card {
  border: 1px solid #ccc;
  padding: 2rem;
  border-radius: 10px;
  width: 250px;
}

.qr-placeholder {
  background-color: #f1f1f1;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.qr-placeholder img {
  max-width: 100%;
  max-height: 250px;
  height: auto;
  width: auto;
}

.feature-highlight {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.feature-highlight ul {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
}

.feature-highlight li {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.exchange-footer {
  margin-top: 3rem;
  color: #777;
  font-size: 0.9rem;
}

.terminate-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  font-family: sans-serif;
  text-align: center;
}

.terminate-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.step-section {
  margin: 2rem 0;
  text-align: left;
}

.step-section h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.screenshot {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 1rem 0;
}

.note {
  background-color: #f9f9f9;
  border-left: 4px solid #e74c3c;
  padding: 1rem;
  margin-top: 2rem;
  font-size: 0.95rem;
}

