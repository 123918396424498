.team-selection-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  max-height: 700px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
}

.team-selection-modal h3 {
  margin-bottom: 10px;
  color: #333;
}

.team-selection-modal p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.team-stats {
  display: flex;
  justify-content: space-around;
}

.team {
  width: 45%;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.team h4 {
  margin-bottom: 5px;
  font-size: 16px;
}

.team p {
  margin-bottom: 15px;
  font-size: 14px;
}

.team button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.team.gold {
  background-color: rgba(227, 184, 102, 0.1);
}

.team.blue {
  background-color: rgba(40, 127, 148, 0.1);
}

.team.gold button {
  background-color: #E3B866;
}

.team.blue button {
  background-color: #287F94;
}

.team button:hover {
  opacity: 0.9;
}

