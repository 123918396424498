/* Container Layout */
.game-analysis-container {
  display: flex;
  min-height: 100vh;
}

.username-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.custom-pgn-entry {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.custom-pgn-entry textarea {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.color-selection {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.color-selection label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.delete-button {
  background: none;
  border: none;
  color: #888; /* Danger red */
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.delete-button:hover {
  color: #333; /* Darker red on hover */
}

.toggle-manage-button {
  width: 100%;
  padding: 5px;
  font-size: .9rem;
  background-color: #f2f2f9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-manage-button:hover {
  background-color: var(--highlight-color-dark);
}

/* Sidebar */
.game-list-sidebar {
  width: 300px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto !important;
  height: calc(100vh - 100px); /* Adjust 60px to match your header height */
}

.game-list-sidebar-header {
  font-size: 18px;
  font-weight: bold;
}

.game-list-item {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: background-color 0.2s ease;
}

.game-list-item:hover {
  background-color: white;
  padding: 10px;
}

.game-list-item p {
  font-size: 14px;
  margin: 0 0 5px 0;
}

.game-list-item a {
  font-size: 12px;
  margin: 0px;
  display: inline-block;
  color: #007bff;
}

.game-list-item a:hover {
  text-decoration: underline;
}


.chesscom-game {
  background-color: #F0FFF0; /* A subtle greenish off-white */
}

.lichess-game {
  background-color: #f0f8ff; /* Light purple */
}

/* Game header */
.game-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.platform-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Game body */
.game-body {
  margin-bottom: 10px;
}

.game-opponent,
.game-date,
.game-result {
  font-size: 0.9rem;
  margin: 5px 0;
}

/* Badges for result */
.badge {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.win-badge {
  background-color: #28a745; /* Success green */
}

.loss-badge {
  background-color: #dc3545; /* Danger red */
}

.draw-badge {
  background-color: #888;
}

.evaluation-comparison {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.move-evaluation {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.move-evaluation.revisit {
  border-color: #ff6961;
  background-color: #ffecec;
}

.move-evaluation h4 {
  margin: 0 0 10px;
}

/* View game link */
.view-game-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 0.85rem;
  color: #007bff;
  text-decoration: none;
}

.view-game-link:hover {
  text-decoration: underline;
}

/* Username input section */
.username-input-section {
  margin-bottom: 20px;
}

.username-input-section input {
  width: calc(100% - 20px);
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.error {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 10px;
}

.game-platform {
  font-size: 0.8em;
  color: #555;
  text-transform: uppercase;
}

.username-input-section {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.username-input-section {
  margin-top: 20px;
}

.username-input-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.username-input-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.save-username-button {
  width: 100%;
  padding: 8px 0;
  margin-top: 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: var(--highlight-color);
  color: white;
  transition: background-color 0.2s ease;
}

.save-username-button:hover {
  background-color: #0056b3;
}

.game-date-section {
  margin-top: 0px;
  padding: 5px 15px;
}

.game-date-header {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

/* Main Content */
.game-analysis-content {
  flex: 1;
  padding: 20px;
}

.game-analysis-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.game-analysis-message {
  font-size: 16px;
  color: #555;
}

.prompt-box {
  grid-column: 1;
  position: relative;
  grid-row: 1;
  width: 96%;
  box-sizing: border-box;
  justify-self: right;
  min-height: 400px;
  min-width: 300px;
  max-height: 900px;
  background-color: #ffffff;
  border-radius: 12px; /* Rounded corners */
  padding: 15px; /* Inner spacing */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* Subtle shadow */
  border: 1px solid #e5e7eb; /* Light border for structure */
  font-family: var(--font), sans-serif; /* Clean font */
  color: #374151; /* Neutral text color */
  overflow-y: auto; /* Handle overflow content */
}

/* Add hover effect for interactive design (if needed) */
.prompt-box:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.08);
}

/* Add styling for content within the box */
.prompt-box h1, .prompt-box h2 {
  margin: 0;
  font-weight: bold;
  color: #111827;
}

.prompt-box p {
  margin: 5px 0;
  line-height: 1.0;
}

.stars {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.star {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
}

.star.selected {
  color: #f39c12;
  background-color: transparent;
}

/* Adjust line spacing and element spacing */
.prompt-box h4 {
  margin: 2px 0; /* Reduce default margin */
  font-size: 16px; /* Optional: Adjust font size */
}

textarea {
  width: 100%; /* Make it span the full container width */
  box-sizing: border-box; /* Include padding and border in width */
  min-height: 150px; /* Reduce height for compactness */
  padding: 10px;
  font-size: 14px;
  line-height: 1.2; /* Adjust internal spacing */
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  margin-bottom: 10px; /* Add spacing below the textarea */
}

/* Add consistent spacing between sections */
.prompt-box > *:not(:last-child) {
  margin-bottom: 10px; /* Adds spacing between sections without extra margin at the end */
}

.flag-review-container {
  position: absolute;
  top: 10px; /* Adjust as needed for spacing */
  right: 10px;
  cursor: pointer;
}



.flag-review-label {
  font-size: 12px;
  color: rgba(100, 105, 95, 0.4);
  user-select: none; /* Prevent text selection on click */
}

.opening-trainer-link-container {
  margin-top: 1rem;
  text-align: center;
}

.opening-trainer-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: background-color 0.2s;
}

.opening-trainer-link:hover {
  background-color: #eaeaea;
}


/* Responsive Design */
@media (max-width: 768px) {
  .game-list-sidebar {
    width: 100%;
    border-right: none;
    box-shadow: none;
    padding: 10px;
    height: 100vh;
  }

  .game-analysis-content {
    padding: 10px;
  }

  .save-username-button {
    margin-top: 15px;
  }
}
