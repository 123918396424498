.stats-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.stats-modal-content {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 900px;
  height: 90%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  overflow-y: auto;
}

.stats-modal-content h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333333;
}

.stats-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stats-column {
  flex: 1;
  margin: 0 10px;
}

.stats-column h4 {
  text-align: center;
  font-size: 1.4rem;
  color: #007bff;
  margin-bottom: 10px;
}

.stats-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.stats-column li {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #555555;
}

.stats-column li strong {
  color: #000000;
}

.stats-chart {
  margin-top: 20px;
}

.close-stats-modal {
  display: block;
  margin: 1rem auto;
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-stats-modal:hover {
  background-color: #0056b3;
}

.stats-table {
  margin: 20px 0;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.stats-table table {
  width: 100%;
  border: 1px solid #ddd;
}

.stats-table th, .stats-table td {
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
}

.stats-table th {
  background-color: #f4f4f4;
}

.stats-table td {
  width: 33.33%; /* Adjust the percentage based on the number of columns */
  text-align: center; /* Optional: Align content */
}

.player-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
}

.player-name {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}

.accuracy {
  font-size: 1.1rem;
  color: white;
  background-color: var(--highlight-color);
  border-radius: 20px;
  padding: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.player-selection {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-group {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 25px;
  overflow: hidden;
  background-color: #f9f9f9;
}

.player-button {
  flex: 1;
  padding: 5px 10px;
  border: none;
  background: none;
  color: #555;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.player-button.active {
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 10px;
}

.player-button:not(.active):hover {
  background-color: #ddd;
  border-radius: 10px;
}

.game-dynamics {
  font-family: "Arial", sans-serif; /* Clean and professional font */
  font-size: 16px; /* Readable text size */
  color: #333; /* Neutral dark gray text */
  margin-top: 10px; /* Add spacing above the description */
  line-height: 1.6; /* Add comfortable line spacing */
  text-align: justify; /* Align text for a professional appearance */
  background-color: #f9f9f9; /* Subtle background to differentiate */
  border-left: 4px solid var(--highlight-color); /* Accent border for emphasis */
  padding: 10px 15px; /* Add inner padding for spacing */
  border-radius: 4px; /* Slightly rounded corners */
}

.line-before-button {
  margin: 20px 0; /* Space around the line */
  border: none; /* Remove border if using <hr> */
  height: 1px; /* Thickness of the line */
  background-color: #ccc; /* Color of the line */
  width: 100%; /* Full width */
}

.line {
  margin: 10px 0; /* Space around the line */
  border: none; /* Remove border if using <hr> */
  height: 1px; /* Thickness of the line */
  width: 100%; /* Full width */
}
