.split-screen-container {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.split-left {
    width: 75%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
}

.split-left .overlay-credentials {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.4; /* Reduce brightness of the video */
}

.background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(0.7);
    transition: transform 3s ease-in-out;  /* To add subtle movement */
}


.logo-container {
    z-index: 1;
    text-align: center;
}

.logo-img {
    width: 150px;
    margin-bottom: 20px;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2));
}

.slogan {
    color: #fff;
    font-family: var(--font);
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

/* Gradient Overlay */
.split-left:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--primary-color) 0%, var(--highlight-color-trans) 100%);
    opacity: 0.6; /* So that the image behind is still visible */
    z-index: 0;
}

.split-right {
    width: 25%;
    background: var(--background-color);
    display: flex;
    justify-content: center;
    padding: 40px;
    box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.1);
}

.signup-container {
    width: 100%;
    max-width: 300px;
}

h2 {
    font-weight: 700;
    color: var(--text-color1);
    margin-bottom: 20px;
}

h2 .highlight-text {
    color: var(--primary-color);
}

input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    font-family: var(--font);
}

.btn-signup, .btn-google-signup {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.btn-signup {
    background-color: var(--highlight-color);
    color: #fff;
}

.btn-signup:hover {
    background-color: var(--highlight-color-dark);
}

.btn-google-signup {
    background-color: #ffffff;
    color: var(--primary-color);
    border: 1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-google-signup:hover {
    transform: scale(1.05);
}

.btn-google-signup img {
    width: 20px;
    margin-right: 8px;
}

.divider {
    text-align: center;
    margin: 20px 0;
    color: var(--text-color2);
    font-size: 0.9rem;
    position: relative;
}

.divider span {
    background: var(--background-color);
    padding: 0 10px;
}

.signup-link, .social-login {
    text-align: center;
}

.signup-link a {
    color: var(--primary-color);
    text-decoration: none;
}

.signup-link a:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

/* Updated Logo Container for consistency */
.logo-container {
    z-index: 1;
    text-align: center;
    margin-top: 20px;
    position: absolute;
    top: 20%;
}

/* Enhanced Quote Carousel Styling */
.quote-carousel {
    margin-top: 20px;
    padding: 15px;
    max-width: 95%;
    position: relative;
    overflow: hidden; /* Ensure that overflow text doesn't show */
}

/* Enhanced Quote Text with Shadow and Fade Animation */
.quote-text {
    color: white;
    font-family: var(--font);
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.4;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6); /* Add a subtle text shadow to make text pop */
    transition: opacity 1s ease-in-out; /* Smooth transition for opacity changes */
    animation: fadeIn 1s ease-in-out forwards; /* Apply fade in animation */
}

.fade-in {
    opacity: 1; /* Fully visible during fade-in */
}

.fade-out {
    opacity: 0; /* Fully invisible during fade-out */
}

@media (max-width: 768px) {
.split-left {
    display: none;
}
.split-right {
    width: 100%;
}
}