.club-page {
  padding: 40px;
}

.club-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Center everything horizontally */
  padding: 20px;
  border-radius: 10px;
  color: var(--text-color1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center; /* Ensures text content is centered */
  height: 345px;
}

.members-count {
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.count {
  font-size: 3rem; /* Larger font size */
  margin-left: 10px;
  color: var(--highlight-color);
  font-weight: bold;
  display: inline-block;
  animation: pulse 2s infinite ease-in-out; /* Animation for a subtle "pulse" effect */
}

.club-logo {
  width: 200px;
  height: 200px;
  margin-right: 40px;
}

.club-info {
  display: flex;
  flex-direction: column; /* Change this to column to stack items in rows */
  text-align: center; /* Center text content within .club-info */
  align-items: center; /* Center the items horizontally */
}


.matches-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: left; /* Distribute cards evenly */
  gap: 20px;
  margin-top: 30px;
}

.match-card {
  background-color: var(--primary-color);
  border: 1px solid black;
  width: 200px;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  color: white;
  min-height: 140px;
}

.match-card.selected {
  background-color: var(--highlight-color-dark); /* Add a different background for selected */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.match-card a {
  color: var(--text-color1);
  text-decoration: none;
}

.match-card a:hover {
  text-decoration: underline;
}

.dropdown-header {
  cursor: pointer;
  color: var(--highlight-color);
  transition: color 0.3s;
}

.dropdown-header:hover {
  color: var(--highlight-color-dark);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Grayed-out background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.board-modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 900px;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

/* Game Selector */
.game-selector ul {
  padding: 0;
  margin: 0;
  order: 10;
}

.game-selector li {
  list-style: none;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.game-selector li:hover {
  background-color: var(--highlight-color-dark);
}

/* Navigation Controls */
.controls {
  margin: 10px 0;
}

.controls button {
  margin: 0 10px;
}

 /* Modal Overlay */
.replay-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%; /* Responsive width */
  height: 90%; /* Limit height to prevent overflow */
  overflow-y: auto; /* Enable scrolling inside modal if content exceeds height */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

/* Chessboard Navigation Controls */
.controls {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.controls button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.controls button:disabled {
  background-color: #ccc;
}

/* Game Selector */
.game-selector {
  display: flex;
}

.match-detail-container ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap; /* Allows wrapping to keep items in a row when space is limited */
}

.game-display-item {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px; /* Reduced vertical padding */
  background-color: #444; /* Card background for each game */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 500px; /* Increased width for more horizontal space */
  max-height: 40px; /* Fixed height to control vertical space */
}

.game-display-item:hover {
  background-color: var(--highlight-color-dark); /* Slightly darker on hover */
}

.selected-game {
  background-color: var(--highlight-color); /* Highlight selected game */
  border: 1px solid #999;
}

.white-player {
  color: #ffffff;
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap; /* Prevents text from wrapping */
}

.black-player {
  color: #000000;
  font-weight: bold;
  white-space: nowrap; /* Prevents text from wrapping */
}

.vs-text {
  color: #ccc;
  margin: 0 8px;
  white-space: nowrap; /* Prevents text from wrapping */
}

.match-detail-container {
  margin: 20px;
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: black;
}

.team-info {
  font-size: 16px;
  margin: 5px 0;
}

.games-list {
  list-style-type: none;
  padding-left: 0;
}

.player-button {
  background-color: var(--highlight-color);
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.player-button:hover {
  background-color: var(--highlight-color-dark);
}

h3 {
  margin-top: 20px;
  font-size: 18px;
}

.mvp-section {
  margin: 20px 0;
  justify-content: space-between;
  background-color: #ffe066; /* Softer golden shade */
  padding: 20px;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  color: #333; /* Darker color for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: 2px solid #ffcc00; /* Subtle border to define the section */
}

.mvp-section h3 {
  margin-bottom: 10px;
  font-size: 1.5em; /* Larger heading */
  color: #333; /* Darker color */
}

.mvp-section p {
  font-size: 1.2em;
  color: #555; /* Slightly lighter for better readability */
}

/* For screens smaller than 768px */
@media only screen and (max-width: 768px) {

  .club-page {
    padding: 20px; /* Reduce padding for small screens */
  }

  .club-header {
    flex-direction: column; /* Stack the logo and info vertically */
    padding: 15px; /* Reduce padding */
    height: auto; /* Remove fixed height */
  }

  .club-logo {
    width: 100px; /* Make the logo smaller */
    height: 100px;
    margin: 0 auto 20px; /* Center the logo and add spacing */
  }

  .club-info h1 {
    font-size: 1.8rem; /* Decrease font size for header */
    text-align: center; /* Ensure text is centered */
  }

  .members-count {
    margin-top: 5px;
    font-size: 16px; /* Adjust font size */
  }

  .count {
    font-size: 2rem; /* Reduce font size for the count */
    margin-left: 5px;
  }

  .matches-section {
    display: block; /* Stack matches vertically for small screens */
    margin-top: 20px;
  }

  .match-card {
    width: 100%; /* Ensure match cards take full width */
    padding: 0px; /* Reduce padding */
    margin: 10px 0; /* Adjust spacing */

  }

  .match-card.selected {
    width: 100%; /* Ensure selected match card takes full width */
  }

  .mvp-section {
    padding: 15px; /* Adjust padding */
    font-size: 0.9rem; /* Reduce font size for MVP section */
  }

  .board-modal-content {
    width: 100%; /* Ensure modal is responsive */
    min-width: auto; /* Remove min-width constraint */
        background-color: transparent;

  }

  .modal-content {
    padding: 0px; /* Adjust padding for modal content */
    max-width: 100%; /* Ensure modal content fits small screens */
    height: auto; /* Remove height constraint */
  }

  .player-button {
    font-size: 14px; /* Reduce button font size */
    padding: 4px 6px; /* Adjust padding */
  }

  .controls button {
    padding: 5px 8px; /* Reduce button size in navigation controls */
  }

  /* Close Button */
.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #999;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.game-selector {
  position: absolute;
  bottom: 40px;
  left: 50vp;
}
}
