/* General Layout */
.blindfold-game-layout {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: var(--font);
  background-color: var(--background-color);
  border-radius: 8px;
}

.blindfold-info-container {
  display: flex;
  justify-content: space-between;
}

.blindfold-info-container p {
  margin: 0;
  font-size: 18px;
  color: var(--text-color1);
}

.label {
  font-weight: 600;
  color: var(--primary-color);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Grayed-out background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}


/* Puzzle Container */
.blindfold-puzzle-container {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.blindfold-puzzle-container h3 {
  margin-top: 0;
  color: var(--highlight-color);
}

.blindfold-puzzle-container ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.blindfold-puzzle-container li {
  font-size: 16px;
  color: var(--text-color2);
  margin-bottom: 5px;
}

.blindfold-puzzle-container li:before {
  content: "•";
  color: var(--highlight-color);
  margin-right: 8px;
}

/* Form Styling */
.blindfold-puzzle-container form {
  display: flex;
  flex-direction: column;
}

.blindfold-puzzle-container label {
  font-size: 16px;
  color: var(--text-color1);
  margin-bottom: 10px;
}

.blindfold-puzzle-container input[type="text"] {
  padding: 8px;
  font-size: 16px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

.blindfold-puzzle-container input[type="text"]:focus {
  border-color: var(--highlight-color);
  outline: none;
}

/* Error Message */
.error-message {
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: var(--dark-text);
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: var(--highlight-color-dark);
}

/* Chessboard Container */
.blindfold-chessboard-container {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
  z-index: 1;
}

.toggle-board-button {
  padding: 8px 12px;
  font-size: 16px;
  color: #ffffff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  height: 40px;
}

.toggle-board-button:hover {
  background-color: var(--highlight-color);
}

.toggle-board-button:active {
  transform: scale(0.98);
}

.next-puzzle-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: var(--highlight-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.rating-accuracy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  position: relative;
  height: 150px;
}

.next-puzzle-button:hover {
  background-color: var(--highlight-color-dark);
}

.next-puzzle-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.next-puzzle-button:active {
  background-color: var(--highlight-color-dark);
  box-shadow: 0 0 0 1px rgba(40, 167, 69, 0.7);
}

.blindfold-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.give-up-button2 {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
}

.give-up-button2:hover {
  background-color: var(--highlight-color);
}

.give-up-button3 {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: var(--highlight-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.give-up-button3:hover {
  background-color: var(--highlight-color-dark);
}

.feedback-message {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

/* Style for correct feedback */
.feedback-message.correct {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  box-shadow: 0 0 10px rgba(0, 200, 0, 0.5);
}

/* Style for incorrect feedback */
.feedback-message.incorrect {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

.chess-piece-table {
  width: 60%;
  margin-left: 0;
  margin-right: auto;
  border-collapse: collapse;
}

.chess-piece-table th,
.chess-piece-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.chess-piece-table th {
  background-color: #f0f0f0;
}

.chess-piece-table th.black {
  background-color: #333;
  color: white;
}

@media (max-width: 768px) {
  .blindfold-game-layout {
    padding: 10px;
  }

  .blindfold-info-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .blindfold-puzzle-container {
    padding: 10px;
  }

  .blindfold-chessboard-container {
    width: 70%;
    margin-top: 15px;
  }

  .toggle-board-button {
    width: 100%;
  }

  .next-puzzle-button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .give-up-button2 {
    padding: 8px 12px;
  }
}

@media (max-width: 480px) {
  .blindfold-game-layout {
    padding: 5px;
  }

  .blindfold-puzzle-container {
    padding: 5px;
  }

  .next-puzzle-button,
  .give-up-button2 {
    font-size: 12px;
    padding: 6px 10px;
  }

  .chess-piece-table {
    width: 100%;
  }
}
