.candidate-container {
  margin-top: 0%;
  grid-column: 1; /* Right side */
  grid-row: 1;
  min-width: 220px; /* Minimum width */
  min-height: 40%; /* Minimum height of its grid row */
  max-height: 100%; /* Maximum height of its grid row */
  justify-self: right;
  margin-right: 0px;

}

.candidate-moves-list {
  list-style: none;
  padding-left: 0;
  overflow-y: auto;
  max-height: 300px;
}

.move-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.move-item.highlight {
  background-color: #fff9c4; /* Highlight color for top moves */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.move-item:hover {
  background-color: #f0f0f0;
}

.move-text {
  font-weight: bold;
}

.like-btn {
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.like-btn:hover {
  opacity: 0.9;
}


.chessboard-and-moves {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.chessboard-section {
  width: 50%;
  padding: 10px;
}

.add-move-section,
/* Container for the add message section */
.add-message-section {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

/* Input for entering the message */
.message-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%; /* Full width */
  height: 40px; /* Adjust height as desired */
  box-sizing: border-box; /* Include padding and border in element's total width */
}

/* Button to add the message */
.add-message-btn {
  margin-left: 10px;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.resign-btn {
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

/* Team-specific button colors */
.add-message-btn.team-gold {
  background-color: #E3B866;
}

.add-message-btn.team-blue {
  background-color: #287F94;
}


.forum-section {
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
  width: 100%;
  max-width: 1100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* This centers the element horizontally */
  margin-bottom: 20px;
}

.forum-section ul {
  list-style-type: none;
  padding-left: 0;
}

.forum-section li {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
}

.message-list {
  list-style: none;
  padding-left: 0;
}

.message-item {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.message-content {
  display: flex;
  align-items: center;
}

.message-username {
  font-weight: bold;
  margin-right: 10px;
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.message-item {
  display: flex;
  justify-content: space-between; /* Keeps elements on opposite sides */
  align-items: center; /* Aligns items vertically */
  padding: 10px;
  margin: 10px 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc; /* Optional for better definition */
}

.message-content {
  flex-grow: 1; /* Makes it take up all remaining space */
  text-align: left; /* Ensures text is left-aligned */
}

.message-username {
  font-weight: bold;
  margin-right: 10px; /* Space between username and message */
}

@media (max-width: 768px) {
  .chessboard-and-moves {
    flex-direction: column;
    align-items: center;
  }

  .chessboard-section,
  .moves-section {
    width: 100%;
  }

  .forum-section {
    width: 100%;
    max-width: 100%;
  }

.candidate-moves-list {
  width: 95vw;
  max-height: 20vh;
}

.candidate-container {
  margin: 0;
  grid-column: 3; /* Right side */
  width: 100%;
  height: auto;
  box-sizing: border-box;
  min-height: 50px;
  justify-self: left;
  margin-right: 0px;
  order: -1;
}

.add-message-section {
  display: flex;
  margin-top: 0px;
  width: 90vw;
}

.forum-section {
  padding: 10px;
  width: 90%;
  max-width: 1100px;
  margin: 0 auto; /* This centers the element horizontally */
  margin-bottom: 20px;
}

.move-item {
  padding: 10px;
  margin: 0px 0;
}

}
