/* LoadGame.css */
.load-game-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f5f5f5; /* Light grey background */
}

.load-game-header {
  text-align: center;
  color: #333; /* Dark grey text for better readability */
  margin-bottom: 30px; /* Add some space below the header */
}

.game-item {
  border: 1px solid #ddd; /* Lighter border color */
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white; /* White background for the game items */
  border-radius: 8px; /* Rounded corners for the game items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s; /* Smooth transition for hover effect */
  min-height: 220px; /* Ensure there's enough height for the chessboard */
}

.game-item:hover {
  transform: translateY(-3px); /* Slight lift effect on hover */
}

.game-details {
  color: #333; /* Dark grey text for better readability */
}

.game-details h2 {
  margin-top: 0;
  color: var(--primary-color); /* Use a theme color for headings */
}

.game-details p {
  margin: 5px 0; /* Reduce vertical margin for p elements */
  color: #666; /* Lighter text color for details */
}

.chessboard-preview-container {
  width: 200px;
  height: 200px;
  flex-shrink: 0; /* Prevent shrinking in flex container */
  display: flex; /* Use flex to center the content (if needed) */
  justify-content: center;
  align-items: center;
}

/* Add additional styles as needed */
