.move-trainer-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr; /* Sidebar takes 250px, chessboard takes remaining space */
  height: 100vh;
}

.file-structure-sidebar {
  width: 280px;
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
  padding: 10px;
  z-index: 10;
}

.chessboard-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.result-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  z-index: 10;
}

.result-message h3 {
  color: var(--highlight-color);
}

.controls {
  margin-top: 20px;
  text-align: center;
}

.controls button {
  margin: 5px;
  padding: 10px 20px;
  background-color: var(--highlight-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.controls button:hover {
  background-color: var(--highlight-color-dark);
}

.folder {
  margin-left: 10px;
}

.main-folder .folder-header {
  font-weight: bold;
  color: blue;
}

.sub-folder .folder-header {
  font-weight: normal;
  color: darkgreen;
  margin-left: 15px;
}

.action-button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
  display: block;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.action-button:hover {
  background-color: #0056b3;
}

.note-header {
  background-color: var(--highlight-color-trans);
  padding: 0.5rem;
  margin: 5px;
  border-bottom: 1px solid #ccc;
  justify-self: center;
}

.note-header h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.note-display.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.note-item {
  margin-bottom: 1rem;
}

.note-move-info {
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 0.3rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.note-container2 {
  grid-column: 1;
  grid-row: 1;
  justify-self: right;
  margin-left: 20px;
  padding: 4px;
  border: none;
  border-radius: 4px;
  height: 50vh; /* Limit its height */
  font-size: 15px;
  min-width: 200px;
  width: 90%;
  max-width: 450px;
  line-height: 1.0; /* Adjust line spacing here */
}

.note-container {
  grid-column: 1;
  grid-row: 1;
  justify-self: right;
  margin-left: 20px;
  padding: 12px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 60vh; /* Limit its height */
  overflow-y: auto; /* Add scroll if content overflows */
  margin-bottom: 8px; /* Space between note and move history */
  font-size: 15px;
  min-width: 200px;
  width: 90%;
  max-width: 450px;
  line-height: 1.0; /* Adjust line spacing here */
}

.modal-overlay {
    position: fixed; /* Cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent gray */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
}

.save-line-modal {
    background: white; /* Modal background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    z-index: 1100; /* Ensure it is above the overlay */
}
/* SaveLineModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}



.save-line-modal-content h2 {
  color: var(--primary-color);
  font-size: 1.5em;
}

.modal-input {
  width: 90%;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  color: var(--text-color1);
  font-family: var(--font);
}

.directory-inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.modal-select {
  padding: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  background-color: var(--light-square-color);
  color: var(--text-color1);
  font-family: var(--font);
}

.modal-button {
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  color: var(--background-color);
  font-family: var(--font);
  transition: background-color 0.3s;
}

.modal-button.save {
  background-color: var(--highlight-color);
}

.modal-button.cancel {
  background-color: #999;
}

.modal-button:hover.save {
  background-color: var(--highlight-color-dark);
}

.modal-button:hover.cancel {
  background-color: #888;
}

/* Entry Method Buttons */
.entry-methods button {
  padding: 8px 16px;
  font-size: 1rem;
  color: #fff;
  background-color: var(--highlight-color);
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.entry-methods button:hover {
  background-color: var(--highlight-color-dark);
}

/* PGN Input Section */
.pgn-input {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.pgn-input input[type="text"],
.pgn-input textarea {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

/* Save Button */
.pgn-input button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #444;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pgn-input button:hover {
  background-color: #333;
}

/* Cancel Button */
.save-line-modal > button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #333;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-line-modal > button:hover {
  background-color: #e2e2e2;
}

.note-input {
  width: 100%;
  height: 50vh;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font: var(--font);
  font-size: 15px;

}

.note-input:focus {
  outline: none;
  border-color: var(--highlight-color);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media (max-width: 768px) {

.file-structure-sidebar {
  width: 100vw;
}

.note-container {
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  max-height: 20vh;
  margin-left: 0px;
}

.note-container2 {
  width: 100%;
  max-width: 600px;
  margin-left: 0px;
  box-sizing: border-box;
  max-height: 20vh;
}

}