/* General Info Container Styles */
.info-container {
  grid-column: 3;
  padding: 25px;
  font-size: 17px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: var(--chessboard-size);
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  justify-self: left;
  min-width: 220px;
}

/* Rating and Accuracy */
.rating-accuracy {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 30px;
  background-color: transparent;
  align-self: center;
  flex-direction: column;
}

.rating-box,
.accuracy-box {
  background-color: var(--highlight-color); /* Light blue background for separation */
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 80px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rating-box {
  background-color: #f3f3f3; /* Light yellow for accuracy */
  color: #666;
}
.rating-box {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  background-color: #f3f3f3; /* Light yellow for accuracy */
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 0.9em;
  font-weight: bold;
  color: #555;
  display: none; /* Hidden by default */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Time Controls */
.time-controls {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.accuracy-feedback {
  font-size: 0.8em; /* Small text */
  margin-top: 0px; /* Spacing above */
  color: rgba(170, 175, 175, 1); /* Subtle color */
  font-weight: bold; /* Highlighted but subtle */
  text-align: center; /* Centered text */
  opacity: 0.8; /* Slight transparency */
  font-size: 10px;
}

.time-buttons {
  display: flex;
  gap: 8px;
}

.rating-container:hover .rating-box {
  display: block; /* Show on hover */
}

.time-buttons button {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #D3D3D3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.time-buttons button:hover {
  background-color: var(--highlight-color-dark);
}

/* Endgame Filter */
.endgame-filter {
  display: flex;
  justify-content: center;
}

.endgame-filter button {
  padding: 8px 12px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.active-filter {
  background-color: var(--highlight-color-dark);
}

.inactive-filter {
  background-color: var(--highlight-color-trans);
}

/* Buttons Container */
.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; /* Space between rows */
  width: 100%; /* Full width for centering */
}

/* Center each row of buttons */
.puzzle-button-row {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 15px; /* Space between buttons */
  width: 100%; /* Ensure buttons align properly */
}

/* icon-options Button */
.icon-options-button {
  padding: 4px 9px;
  background-color: var(--secondary-color); /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Adjust the button image */
.icon-options-button img {
  width: 29px; /* Sets a fixed width for the image */
  height: auto; /* Sets a fixed height for the image */
}

/* Hover effects */
.icon-options-button:hover {
  background-color: var(--highlight-color-dark); /* Slightly darker shade */
}

.icon-options-button:active {
  transform: scale(1); /* Return to normal size */
}

.moves-back-container {
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  margin-top: 10px;
}

.moves-back-label {
  margin-right: 10px;
  font-weight: bold;
}

.moves-back-input {
  width: 50px;
  padding: 5px;
}

/* Help Link */
.help-link {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  background-color: white;
  color: var(--highlight-color);
  border: 1px solid var(--highlight-color);
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.help-link a {
  color: var(--highlight-color);
  font-weight: bold;
  text-decoration: none;
}

.help-link:hover {
  background-color: var(--highlight-color-dark);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.help-link a:hover {
  text-decoration: underline;
}

.moves-back-container input {
  margin-bottom: 14px; /* Adjust the size of the space as needed */
}

/* Puzzle Title */
.puzzle-title {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

/* History List */
.history-list {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 30vh;
}

/* Secondary Info Styles */
.info-container .no-media {
  font-size: 14px;
  color: #666;
}

/* Puzzle Button */
.puzzle-button {
  padding: 12px 24px;
  background-color: var(--highlight-color);
  color: #fff;
  border: none;
  border-radius: 8px;
  width: 80%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.puzzle-button:hover {
  background-color: var(--highlight-color-dark);
  transform: scale(1.05);
}

.puzzle-button:active {
  transform: scale(1);
}

/* Login Message */
.login-message {
  background-color: #f8f9fa;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 80%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--highlight-color);
}

.close-button:hover {
  color: var(--highlight-color-dark);
}

.login-message a {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.login-message a:hover {
  text-decoration: underline;
}

/* Table Styles */
.table-container {
  max-height: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

thead th {
  background-color: #f9f9f9;
  padding: 5px;
  border-bottom: 2px solid #ddd;
  font-size: 13px;
}

tbody tr {
  border-bottom: 1px solid #e0e0e0;
}

.move-number {
  padding: 3px;
  text-align: center;
  color: #333;
  width: 5%;
}

.move-box {
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.move-box:hover {
  background-color: var(--highlight-color-trans);
}

tbody tr {
  height: 30px;
}

td {
  padding: 8px;
  text-align: center;
}

/* Puzzle Button 2 - Used in Secondary Buttons */
.puzzle-button2 {
  padding: 5px 10px;
  background-color: var(--highlight-color);
  color: #fff;
  border: none;
  border-radius: 8px;
  width: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.puzzle-button2:hover {
  background-color: var(--highlight-color-dark);
  transform: scale(1.05);
}

.puzzle-button2:active {
  transform: scale(1);
}

.left-button {
  flex: 1;
  margin-right: 10px;
  justify-content: flex-start;
}

.right-button {
  flex: 1;
  margin-left: 10px;
  justify-content: flex-end;
}

/* Desktop and Mobile History List Handling */
.desktop-history-list {
  display: block;
}

.mobile-history-list {
  display: none;
}

.settings-container {
  position: relative;
  top: -15px; /* Adjust the negative value to move the container upwards */
  left: -15px;
}


.settings-toggle {
  background-color: var(--background-color);
  color: white;
  border: none;
  cursor: pointer;
}

.settings-options {
  position: absolute;
  border-radius: 8px; /* Rounded corners for a softer design */
  display: flex;
  flex-direction: column;
  padding: 20px; /* Comfortable padding */
  z-index: 1000;
  background-color: var(--background-color);
}

/* Styling for Time Limit and Endgame Toggle Buttons */
.time-limit-selector button,
.endgame-toggle button {
  background-color: var(--highlight-color);   /* Light background for buttons */
  padding: 10px;                 /* Comfortable padding */
  margin: 7px;
  cursor: pointer;              /* Pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background change on hover */
  border-radius: 10px;
  border: none;
  min-width: 50px;
  color: white;
}

/* Hover effects for buttons */
.time-limit-selector button:hover,
.endgame-toggle button:hover {
  background-color: var(--highlight-color-dark);    /* Slightly darker background on hover */
}

/* Optional styling for more button appearance customization */
.time-limit-selector button:active,
.endgame-toggle button:active {
  background-color: #d0d0d0;    /* Even darker when active/clicked */
}

/* Styling for Time Limit and Endgame Toggle Buttons */
.time-limit-selector button,
.endgame-toggle button {
  background-color: var(--highlight-color);   /* Light background for buttons */
  padding: 8px;                 /* Comfortable padding */
  margin: 5px;
  cursor: pointer;              /* Pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background change on hover */
}

/* Hover effects for buttons */
.time-limit-selector button:hover,
.endgame-toggle button:hover {
  background-color: var(--highlight-color-dark);    /* Slightly darker background on hover */
}

/* Optional styling for more button appearance customization */
.time-limit-selector button:active,
.endgame-toggle button:active {
  background-color: #d0d0d0;    /* Even darker when active/clicked */
}

.chess-clock {
  grid-column: 1; /* Place in the first grid column */
  display: flex;
  justify-content: center; /* Center horizontally within the column */
}

.time-display {
  display: flex;
  justify-content: center;
  font-family: 'Digital-7', sans-serif;
  font-size: 48px; /* Adjust size as necessary */
  background-color: black;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px black; /* Soft glow effect */
  width: 80px;
  max-height: 60px;
}

@font-face {
  font-family: 'Digital-7';
  src: url('../../public/fonts/digital-7.ttf') format('truetype');
}

.clock-value {
  margin-left: 10px;
  font-size: 50px;
  color: #d3d3d3;
  font-family: 'Digital-7', sans-serif;
  letter-spacing: 0.1em;
}





/* Media Queries for Mobile Layout */
@media (max-width: 768px) {

  /* Info Container Layout on Mobile */
  .info-container {
    display: flex;
    padding: 0px;
    gap: 10px;
    width: 100%;
    order: -1;
    box-shadow: none;
    border: none;
  }

.settings-container {
  position: relative;
  top: 0px; /* Adjust the negative value to move the container upwards */
  left: 0px;
}

  /* Rating Info on Mobile */
  .rating-info {
    flex: 1;
    text-align: left;
    font-size: 18px;
    color: #333;
    padding: 10px;
    border-radius: 8px;
  }

  /* Buttons on Mobile */
  .buttons-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-right: 10px;
  }

  .puzzle-button {
    margin-right: 5px;
    font-size: 12px;
    padding: 7px;
    cursor: pointer;
    width: 90%;
  }

  /* Mobile Buttons */
  .buttons-container2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-right: 10px;
    margin-top: 50px;
  }

  .puzzle-button2 {
    margin-top: 5px;
    margin-right: 5px;
    font-size: 12px !important;
    padding: 7px;
    cursor: pointer;
    width: 90% !important;
  }

  .left-button, .right-button {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    font-size: 16px;
    width: calc(50% - 10px);
  }

  /* Mobile History List */
  .history-list {
    width: 100%;
    max-width: 350px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: white;
    overflow-y: auto;
  }

.table-container {
  width: 95%;
  max-height: 250px;
  overflow-y: auto;
  margin: 0 auto; /* Centers the container horizontally */
}


  .table {
    width: 100%;
    font-size: 12px;
  }

  .move-number, .move-box {
    padding: 5px;
    text-align: center;
  }

  .move-box:hover {
    background-color: var(--highlight-color-trans);
  }

  .desktop-history-list {
    display: none;
  }

  .mobile-history-list {
    display: block;
    width: 100%;
    max-width: none;
    margin-top: 20px;
    padding: 0px;
    border: 1px solid var(--background-color);
    border-radius: 8px;
    background-color: var(--background-color);
    overflow-y: auto;
  }


.puzzle-title {
  display: none;
}

.time-display {
  padding: 8px 16px;
  border-radius: 10px;
  box-shadow: 0 0 4px black; /* Soft glow effect */
  width: 40px;
  margin-left: 10px;
}


.clock-value {
  font-size: 20px;
  color: #d3d3d3;
  font-family: 'Digital-7', sans-serif;
  letter-spacing: 0.1em;
}

.settings-options {
  border: 2px solid black;
  border-radius: 40px;
  height: auto;
  width: 100vw; /* Adjust width to fill the parent container, not exceed it */
  padding: 10px;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}


.rating-accuracy {
  width: auto;
  margin: 0 auto; /* Centers horizontally */
  display: flex; /* If children need alignment */
  justify-content: center; /* Centers children horizontally */
  text-align: center; /* Align text inside if applicable */
}

.chess-clock {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}


}
