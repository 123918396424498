.discussion-box {
  margin-top: 20px;
  padding: 15px;
  background-color: transparent;
  border-radius: 8px;
}

.add-message-section textarea {
  margin-right: 10px;
  min-height: 20px;
}

.forum-text-area {
  min-height: 20px;
  width: auto;
  min-width: calc(100vh - 20px);
  font: var(--font);
}

.message-list {
  list-style-type: none;
  padding: 0;
  max-height: 900px;
  overflow-y: auto;
}

.message-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.reply-section textarea {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 8px;
}

.reply-box {
  margin-left: 35px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
}

.reply-list {
  margin-top: 10px;
  margin-left: 20px;
  list-style-type: none;
}

.reply-item {
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 8px;
  border-radius: 5px;
}

.forum-btn-container {
  display: flex;
  flex-direction: column;
  margin: 4px;
  }

.forum-like-btn {
  flex-shrink: 0; /* Prevents it from shrinking */
  margin-top: 5px; /* Adds space between the content and button */
  background-color: var(--highlight-color); /* Example color */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  width: 55px;
  height: 30px;
}

.forum-like-btn:hover {
  background-color: var(--highlight-color-dark); /* Darker hover color */
}

.forum-btn-count {
  font-weight: bold;
  font-size: 0.9rem;
  position: absolute;
  top: 2px;
  right: 5px;
}

.forum-btn-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 7px;
  bottom: 5px;
}

.forum-btn-icon:hover {
  transform: scale(1.15);
  transition: .5s ease;
  }

.discussion-container {
  overflow-y: auto;
  max-height: 80%;
  justify-self: center;
  width: 100%; /* Ensures it spans the full width */
  max-width: 900px; /* Optional: restrict maximum width */
}
