/* Parallax Section */
.parallax-section {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh; /* Adjust to your needs */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure content stacks correctly */
  z-index: 1;
}

.feature-header {
    font-size: 2em; /* Adjust as needed for your design */
    font-weight: 700; /* Make it bold */
    text-transform: uppercase; /* Make all text uppercase for emphasis */
    letter-spacing: 2px; /* Add some spacing between the letters */
    color: #333; /* Base color */
    -webkit-background-clip: text;
    margin-bottom: 20px; /* Add spacing below the header */
    transition: transform 0.3s ease-in-out; /* Smooth transition for hover effect */
}


.title-container {
  display: flex;
  align-items: center; /* Center logo and text vertically */
}

/* Logo next to title */
.logo {
  width: 150px; /* Adjust size to fit your design */
  height: auto;
  margin-right: 15px; /* Space between logo and text */
}
.logo {
    opacity: 0;
    transform: translateX(-500px);
    animation: fadeInLeft 1s forwards ease-in-out;
    animation-delay: 0.5s; /* Adds a delay for the title animation */
    transition: transform 0.3s ease-in-out;
}

@keyframes fadeInLeft {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.logo:hover {
    transform: scale(1.1) rotate(10deg);
}

/* Title animation */
.home-title {
    display: flex;
    align-items: baseline;
    opacity: 0;
    transform: translateX(-500px);
    animation: fadeInRight 1s forwards ease-in-out;
    transition: transform 0.3s ease-in-out;
}

@keyframes fadeInRight {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.title-bold {
    font-weight: 700; /* Bold */
    font-size: 4em; /* Adjust to desired size */
}

.title-thin {
    font-weight: 300; /* Thin */
    font-size: 3.5em; /* Adjust if needed */
}


/* Additional style to make sure elements above/below it scroll at different speeds */
.parallax-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure this is behind content */
}

.welcome-section {
    position: absolute; /* Position relative to the parent .parallax-section */
    bottom: 20px; /* Distance from the bottom of the image */
    left: 50%; /* Horizontally center the button */
    transform: translateX(-50%); /* Center alignment correction */
    text-align: center; /* Align text inside if needed */
}

.start-btn {
  padding: 12px 24px;
  font-size: 1.5em;
  background-color: var(--highlight-color);
  color: white;
  border: none;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 0.4s ease, transform 0.3s ease, box-shadow 0.4s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.start-btn:hover {
  background-color: var(--highlight-color-dark);
  transform: translateY(-3px); /* Slight "lift" effect on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Adding an animated gradient border effect */
.start-btn::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(45deg, var(--highlight-color), var(--highlight-color-dark), var(--highlight-color-trans));
  background-size: 400%;
  border-radius: 7px;
  z-index: -1;
  transition: background-position 0.5s ease;
}

.start-btn:hover::before {
  background-position: 100%;
}


/* Grid and Header Styling */
.home-features {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 40px;
    justify-items: center;
}

.train-link-button {
    background: none;
    border: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    color: inherit;
  }
  
  .train-link-button:focus {
    outline: none;
  }
  
  .train-link-button:hover {
    color: var(--highlight-color); /* Optional: change color on hover */
  }
  
  .bonus-section {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #ccc;
    background-color: #fafafa;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .bonus-card {
    display: inline-block;
    width: 320px;
    height: 120px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: none;
    transition: background-color 0.2s ease;
  }
  
  .bonus-card:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
  
  .bonus-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #222;
  }
  
  .bonus-card p {
    font-size: 14px;
    color: #666;
  }
  
  .bonus-button {
    margin-top: 12px;
    padding: 6px 14px;
    background-color: #e3b866;
    color: black;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .bonus-button:hover {
    background-color: #d2a650;
  }
  
  
/* General Styling */
.feature {
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    background-color: #fff;
    margin-bottom: 20px;
}

.feature.fade-in {
    opacity: 1;
    transform: translateY(0);
}

/* Hover Effect */
.feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Feature Card Styling */
.feature-card {
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #333;
}

.feature-card:hover {
    background-color: #f0f0f0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-card h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
    color: var(--highlight-color);
}

.feature-card p {
    font-size: 1em;
    color: #666;
    margin: 0;
}

.home-btn {
    background-color: var(--highlight-color);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.home-btn:hover {
    background-color: var(--highlight-color-dark);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Lottie Animation Section */
.lottie-animation-section {
    margin: 50px 0;
    text-align: center;
}

.lottie-animation {
    margin: 0 auto;
    cursor: pointer;
    width: 300px;
    height: 300px;
}

.animation-header {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.home-train-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

/* Responsive Design */
@media (max-width: 900px) {
  .home-features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .home-features {
    grid-template-columns: 1fr;
    padding: 0px;
  }

      .feature-card {
        text-align: center;
    }

    .parallax-section {
        height: 100vh; /* Let it adjust based on content height */
        padding: 40px 20px; /* Add padding for spacing */
        flex-direction: column; /* Stack elements vertically */
    }

    .title-container {
        flex-direction: column; /* Stack the logo and text vertically */
        text-align: center;
    }

    .logo {
        max-width: 150px; /* Reduce logo size for smaller screens */
        margin-bottom: 10px; /* Add spacing between the logo and text */
    }

    .home-title .title-bold {
        display: block;
        font-size: 2.5rem; /* Scale down the title font size */
        margin-right: 10px;
    }

    .home-title .title-thin {
        display: block;
        font-size: 2.5rem; /* Adjust for mobile readability */
    }
      
/* Parallax Section */
.parallax-section {
    background-size: auto 100%;
 }

 /* General Styling */
    .feature {
        padding: 10px; /* Reduce padding for smaller screens */
        border-radius: 4px; /* Slightly reduce rounded corners */
        margin-bottom: 10px; /* Reduce bottom margin */
        opacity: 1; /* Ensure visibility */
        transform: none; /* Remove initial transform */
        transition: none; /* Remove animation for smoother scroll */
        box-shadow: none; /* Remove shadow for better performance */
    }
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; /* Ensure it appears above all other elements */
}

.modal-content {
    background: var(--background-color);
    border-radius: 8px;
    max-width: 400px; /* Limit maximum width for a good user experience */
    padding: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-height: 250px;
}

.modal-content h2 {
    margin-bottom: 20px;
    color: var(--primary-color);
    font-size: 1.8rem;
    font-weight: bold;
}

.modal-content input {
    max-width: 200px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    font-family: var(--font);
    font-size: 1rem;
    outline: none; /* Remove default outline on focus */
}

.modal-content input:focus {
    border-color: var(--highlight-color);
    box-shadow: 0px 0px 5px var(--highlight-color-trans);
}

.modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.footer {
    text-align: center;
    padding: 20px;
    font-size: 12px;
    color: #555;
    background-color: #f1f1f1;
    position: relative;
    bottom: 0;
    max-width: 100%;
}

.footer-link {
    color: #0066cc;
    text-decoration: none;
    margin-left: 5px;
}

.footer-link:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
    .modal-content {
        padding: 15px;
    }

    .modal-content h2 {
        font-size: 1.5rem;
    }

    .modal-btn {
        font-size: 0.9rem;
        padding: 8px;
    }
    .title-container {
        margin-bottom: 100%;
    }
}

