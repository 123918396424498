.header {
  width: 100%;
  padding: 10px 0;
  background-image: linear-gradient(to right, var(--primary-color), white);
}

.header-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center; /* Align items vertically */
  padding: 0 30px;
}

.header-link {
  display: flex; /* Ensures the button aligns properly */
  align-items: center;
  text-decoration: none;
}

.header-button {
  padding: 0; /* Remove padding around the button */
  border: none; /* Remove border if any */
  background-color: transparent; /* Make button background transparent */
  cursor: pointer;
}

.header-button img {
  height: 70px; /* Adjust based on your preference */
  width: auto; /* Maintain aspect ratio */
}

.profile-dropdown {
  position: absolute;
  display: inline-block;
  right: 2%;
  z-index: 15;
}

.dropdown-content {
  position: absolute;
  top: 100%; /* Adjust this as needed */
  right: 0; /* Aligns the dropdown's right edge with the button's right edge */
  width: auto; /* Or a fixed width, depending on your design */
  display: none; /* Initially hidden */
}

.profile-button {
  background: none;
  border: none;
  cursor: pointer;
}

.profile-button img {
  width: 60px;
  height: 60px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 10;
}

/* Dropdown content shown when active class is added */
.dropdown-content.show {
  display: block;
}

/* Dropdown button style (reset appearance) */
.dropdown-button {
  font-size: inherit;
  padding: 12px 16px;
  border: none;
  background-color: transparent;
  color: inherit;
  display: block;
  width: 100%;
  text-align: left;
  font-style: italic;
  z-index: 15;
}

.dropdown-content span, .dropdown-content a, .dropdown-content button {
  color: #333; /* Slightly darker for better contrast */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.dropdown-content a:hover, .dropdown-content button:hover {
  background-color: #f1f1f1; /* Hover background color */
  color: #555; /* Hover text color */
}

.dropdown-content button {
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.mobile-menu-toggle {
  display: none;
}

.dropdown-button {
  padding: 10px 30px;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 180px;
}

.dropdown-content2 a {
  color: var(--text-color1);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: var(--background-color);
}

.dropdown-content2 a:hover {
  background-color: var(--highlight-color-trans);
}

/* Dropdown content shown when active class is added */
.dropdown-content2.show {
  display: block;
}

/* Media Query for Mobile Devices */
@media (max-width: 850px) {
  .header {
    display: none;
  }

  /* Toggle Button for Mobile Menu */
  .mobile-menu-toggle {
    display: block;
    position: fixed;
    bottom: 0px; /* Margin from the bottom for better accessibility */
    right: 0px; /* Margin from the right side */
    z-index: 21; /* Ensure it's above the mobile menu */
    cursor: pointer; /* Indicates it's clickable */
  }

  .mobile-menu-toggle img {
    height: 30px;
  }

  /* Mobile Menu Container */
  .mobile-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Add a transparent background */
    z-index: 20;
  }

  /* Mobile Menu */
  .mobile-menu {
    background-color: var(--background-color);
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .menu-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .menu-logo {
    height: 60px;
    width: auto;
  }

  /* Mobile Menu Item */
  .mobile-menu-item {
    width: 100%;
    padding: 15px 20px;
    text-align: left;
    border-bottom: 1px solid var(--secondary-color);
    color: var(--text-color1);
    font-size: 18px;
    font-family: var(--font);
    font-weight: 500; /* Make the text slightly bolder */
    cursor: pointer;
    text-decoration: none; /* Remove underline from links */
  }

  .mobile-menu-item:last-child {
    border-bottom: none;
  }

  .mobile-menu-item:hover {
    background-color: var(--highlight-color-trans);
    color: var(--highlight-text-color); /* Change text color on hover */
  }

  /* Dropdown Styling */
  .mobile-dropdown {
    width: 100%;
  }

  .mobile-dropdown-btn {
    width: 100%;
    padding: 15px 20px;
    text-align: left;
    background-color: var(--background-color);
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500; /* Slightly bolder text */
    color: var(--text-color1);
    text-decoration: none; /* Remove underline from links */
  }

  .mobile-dropdown-btn:hover {
    background-color: var(--highlight-color-trans);
    color: var(--highlight-text-color); /* Change text color on hover */
  }

  /* Dropdown Content */
  .mobile-dropdown-content {
    display: none;
    flex-direction: column;
    background-color: var(--background-color);
    padding: 10px;
  }

  .mobile-dropdown-content.show {
    display: flex; /* Toggle visibility using the 'show' class */
  }

  .mobile-dropdown-content .mobile-dropdown-item {
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border-bottom: 1px solid var(--secondary-color);
    color: var(--text-color1);
    text-decoration: none; /* Remove underline from links */
    font-weight: 400; /* Regular font weight */
  }

  .mobile-dropdown-content .mobile-dropdown-item:last-child {
    border-bottom: none;
  }

  .mobile-dropdown-content .mobile-dropdown-item:hover {
    background-color: var(--highlight-color-trans);
    color: var(--highlight-text-color); /* Change text color on hover */
    text-decoration: none; /* No underline on hover */
  }

  /* Optional: Add smooth transitions */
  .mobile-dropdown-content {
    transition: max-height 0.3s ease;
  }

  /* Adjustments to header and profile buttons */
  .header-button img,
  .profile-button img {
    height: 40px;
    width: auto;
  }

  .profile-button {
    padding: 0;
    margin: 5px; /* Consistent margin around the button */
  }

  .profile-button img {
    width: 35px;
    height: 35px;
  }

  .menu-header {
    display: flex; /* Enables flexbox */
    align-items: center; /* Vertically center the contents */
    justify-content: center; /* Horizontally center the contents */
    gap: 10px; /* Space between the logo and the username */
  }

  .menu-logo {
    height: 60px; /* Adjust size accordingly */
    width: auto; /* Maintain aspect ratio */
  }
}
