.file-list {
  display: flex;
  flex-direction: column;
  height: 100%; /* Occupy full height */
}

.collapsible-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.scrollable-section {
  flex-grow: 1; /* Allow this to take the remaining space */
  overflow-y: auto; /* Make this section scrollable */
}

/* Collapsible container with width transition */
.collapsible-container {
  display: flex;
  flex-direction: column;
  width: 99%;
  transition: width 0.3s ease;
}

.collapsible-container .file-list {
  max-height: 80vh; /* Adjust as needed for screen size */
}

/* Button row styling */
.button-row {
  display: flex;
  gap: 8px;
  align-items: left;
  justify-content: left;
  border-bottom: 1px solid #ccc;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
}

.icon-button img {
  width: 24px;
  height: 24px;
}

.icon-button:hover img {
  opacity: 0.7;
}

.collapsed {
  width: 50px; /* Adjust to your desired collapsed width */
  overflow: hidden;
}

/* Style for the collapse button */
.collapse-button {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  border-radius: 20px;
  outline: none;
  transition: background-color 0.2s;
  overflow-y: auto;
}

.collapse-button:hover {
  background-color: #6a6a6a;
}


.file-structure-sidebar {
  width: 250px;
  overflow-y: auto;
  transition: width 0.3s ease;
}

.file-structure-sidebar.collapsed {
  width: 50px;
  overflow: hidden;
}

.toggle-sidebar-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  background: #4a4a4a;
  color: #fff;
  padding: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-sidebar-btn:hover {
  background-color: #6a6a6a;
}

.file-structure {
  display: flex;
  flex-direction: column;
}

.file-structure.collapsed .file-list {
  display: none; /* Hide content when collapsed */
}


.file-list h3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  font-size: 20px;
}

/* Folder Structure */
.folder {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.folder-header {
  margin: 0;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Main folder title */
.main-folder .folder-header {
  color: black !important;
  font-weight: bold !important;
}

/* Sub-folder title */
.sub-folder .folder-header {
  color: var(--primary-color) !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

/* Folder content */
.folder-content {
  padding-left: 20px;
}

/* File Item */
.file-item {
  list-style: none;
  padding: 4px 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

/* Current Directory Info */
.current-directory-info {
  margin-top: 20px;
}

/* Action Buttons */
.folder-button {
  background-color: var(--highlight-color);
  color: white;
  border: none;
  width: 80%;
  padding: 5px 10px;
  cursor: pointer;
  margin: 6px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow */
}

.folder-button:hover {
  background-color: var(--highlight-color-dark); /* Use a slightly darker shade for hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a deeper shadow on hover */
}

.folder-button:active {
  background-color: var(--highlight-color-dark); /* Even darker shade when active */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow on click */
  transform: scale(0.98); /* Adds a subtle click effect */
}

.selected-folder {
  background-color: var(--highlight-color-trans); /* Highlight color */
  color: white;
  font-weight: bold;
}

/* Action Buttons */
.test-button {
  background-color: var(--highlight-color);
  color: white;
  border: none;
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px;
  border-radius: 20px;
}

.test-button:hover {
  background-color: var(--highlight-color-dark); /* Use a slightly darker shade for hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a deeper shadow on hover */
}

.test-button .header-text {
  font-size: 1.2em; /* Larger text size for header */
  font-weight: bold;
  margin-bottom: 5px;
}


@media (max-width: 768px) {

.file-structure-sidebar.collapsed {
  width: 50px !important;
  overflow: hidden;
  position: absolute;
  bottom: 0px;  /* Adjust for alignment */
  left: 55px;
  width: 30px; /* Adjust button size as needed */
  height: 30px;
  background-color: transparent;
}

  .collapse-button {
    position: absolute;
    bottom: 5px;  /* Adjust for alignment */
    left: 0px;
  }

.collapsible-container {
  width: 90%;
}

}